.footer-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 271px;
	/* UI Properties */
	background: #062a4e 0% 0% no-repeat padding-box;
}

.footer-wrapper {
	width: 90%;
	max-width: 1440px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.footer-link-brand-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 60%;
	min-width: 25rem;
}

.nav-brand-wrapper.footer {
	width: 10%;
}

.footer-link-wrapper {
	display: flex;
	justify-content: space-between;
	width: 87%;
}

.footer-link-list {
	font: 10px/20px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
	text-decoration: none;
}

.footer-link-list-title {
	font: Bold 12px/25px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
	margin-bottom: 10%;
}

.moblie-footer-icons {
	display: none;
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 500px) {
	.footer-wrapper {
		flex-direction: column;
	}

	.nav-brand-wrapper.footer {
		display: none;
	}

	.nav-logo-wrapper {
		display: none;
	}

	.moblie-footer-icons {
		display: flex;
		justify-content: space-between;
		width: 100%;
		border-top: 1px solid #ffffff;
		margin-top: 5%;
		padding-top: 5%;
	}

	.footer-link-brand-wrapper {
		min-width: 100%;
	}

	.footer-container {
		padding-top: 5%;
		padding-bottom: 5%;
		height: auto;
	}

	.footer-link-wrapper {
		width: 100%;
	}

	.nav-brand-wrapper.footer.mobile {
		display: flex;
	}

	.nav-logo-wrapper.footer {
		display: flex;
		width: 20%;
	}

	.footer-link-list {
		font: 8px/20px Josefin Sans;
	}
}

@media only screen and (min-width: 1224px) {
	/* Styles */
}

@media only screen and (min-width: 1824px) {
	/* Styles */
}
