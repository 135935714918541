.services-section-container.company {
	background: #f7f7f7 0% 0% no-repeat padding-box;
}

.company-page-first-section {
	display: flex;
	justify-content: flex-end;
}

.first-section-left {
	position: absolute;
	left: 0;
	width: 53%;
	height: 40vh;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	/* padding: 5%; */
	padding-left: 10%;
	padding-right: 10%;
	padding-bottom: 4%;
	padding-top: 4%;
	background-color: white;
	box-sizing: border-box;
}

.first-section-left h1 {
	font: Bold 70px/80px Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	margin-top: 0;
	margin-bottom: 0;
}

.first-section-left p {
	font: 14px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.first-section-right {
	width: 50%;
	height: 50vh;
	/* display: flex; */
	/* background-color: white; */
	display: flex;
	align-items: flex-end;
	background: rgba(249, 196, 186, 0.3) 0% 0% no-repeat padding-box;
	border-bottom: solid #1fc6cd;
}

.first-section-right img {
	width: 100%;
	margin-top: 20%;
}

.company-page-second-section {
	display: flex;
	justify-content: flex-end;
	position: relative;
}

.second-section-left {
	width: 50%;
	position: absolute;
	left: 0;
	top: -3rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.second-section-left-text-group {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin-bottom: 1%;
	align-items: center;
}

.second-section-left-text-number {
	font: Bold 70px/13px Tahoma;
	letter-spacing: 0px;
	color: #1fc6cd;
	background: #eaf5f7 0% 0% no-repeat padding-box;
	border-radius: 11px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 6rem;
	width: 10rem;
	margin-right: 5%;
}

.second-section-left-text-small {
	font: 14px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.second-section-right {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.second-section-right > h3 {
	font: 30px/49px Josefin Sans;
	letter-spacing: 0px;
	font-weight: 600;
	color: #062a4e;
	margin-top: 15%;
}

.second-section-oem-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	background: #eaf5f7 0% 0% no-repeat padding-box;
	padding: 5%;
	box-sizing: border-box;
}

.oem-item-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5%;
}

.oem-image-wrapper {
	width: 20%;
}

.oem-image-wrapper img {
	width: 80%;
	mix-blend-mode: darken;
}
/* 
.company-page-third-section {
} */

.company-page-third-section-header-wrapper {
	padding-left: 5%;
}

.company-page-third-section-header-wrapper.clients {
	padding-left: 0%;
}

.company-page-third-section-header-wrapper h1 {
	font: Bold 70px Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	margin-top: 0px;
	margin-bottom: 0px;
}

.company-page-third-section-header-wrapper h3 {
	text-align: left;
	font: 25px Josefin Sans;
	letter-spacing: 0px;
	color: #1fc6cd;
	margin-top: 0.5rem;
}

.company-page-third-section-body {
	display: flex;
	width: 100%;
}

.company-page-third-section-left {
	width: 45%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 4%;
	padding-bottom: 4%;
	background: #f9c4ba36 0% 0% no-repeat padding-box;
	z-index: 1;
}

.company-page-third-section-left img {
	width: 100%;
	padding-left: 20%;
	position: relative;
}

.company-page-third-section-right {
	width: 55%;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	flex-direction: column;
	/* padding: 5%; */
	box-sizing: border-box;
	z-index: 0;
}

.company-third-section-right-header {
	text-align: center;
	font: 25px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	font-weight: 600;
	width: 65%;
	margin-top: 10%;
	margin-bottom: 5%;
}

.company-third-section-side-text-wrapper {
	display: flex;
	justify-content: center;
	text-align: center;
	width: 100%;
	position: relative;
	background-color: white;
	flex-direction: column;
	align-items: center;
	padding-top: 10%;
	padding-bottom: 10%;
	margin-bottom: 2%;
}

.company-third-section-background {
	position: absolute;
	width: 30%;
}

.company-third-section-background img {
	width: 100%;
}

.company-third-section-qoute-text {
	display: flex;
	justify-content: center;
	text-align: center;
	text-align: center;
	font: 12px/19px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	width: 50%;
}

.company-third-section-qoute-text.name {
	font-size: 20px;
	margin-top: 5%;
	margin-bottom: 2%;
}

.company-third-section-qoute-text.italic {
	text-align: center;
	font: Italic 12px/15px Josefin Sans;
}

.company-page-fourth-section {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10%;
}

.company-page-fourth-section-sub {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 20px #0000000d;
	width: 50%;
	padding: 4%;
	box-sizing: border-box;
}

.company-page-fourth-section-sub.left-top {
	position: absolute;
	left: 0;
	top: -3rem;
}

.company-page-fourth-section-sub.right-bottom {
	position: absolute;
	bottom: -3rem;
	right: 0;
}

.company-page-fourth-section-sub-header {
	font: Bold 12px/30px Josefin Sans;
	letter-spacing: 0.18px;
	color: #062a4e;
}

.company-page-fourth-section-sub-text {
	text-align: left;
	font: 12px/15px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.company-page-fourth-section-image {
	width: 55%;
}

.company-page-fourth-section-image img {
	width: 100%;
}

.company-page-fifth-section {
	margin-top: 13%;
}

.company-page-third-section-body {
	display: flex;
	margin-top: 5%;
}

.company-page-third-section-body-left {
	width: 45%;
	z-index: 2;
	position: relative;
	right: -5%;
	display: flex;
	/* align-items: center; */
	flex-direction: column;
}

.company-page-third-section-body-left__text {
	text-align: left;
	font: 25px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	font-weight: 600;
	width: 65%;
	margin-bottom: 5%;
}

.company-page-third-section-body-left__image {
	width: 100%;
	background: #caeef44a 0% 0% no-repeat padding-box;
}

.company-page-third-section-body-left__image .img {
	width: 100%;
	/* position: absolute; */
	/* left: 5%; */
	padding-top: 10%;
	padding-bottom: 10%;
	padding-right: 12%;
	padding-left: 12%;
	/* background: #caeef44a 0% 0% no-repeat padding-box; */
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.img img {
	box-sizing: border-box;
	mix-blend-mode: darken;
	width: 19%;
	min-width: 3rem;
	/* height: 3rem; */
	margin-right: 2%;
}

.img-top {
	margin-bottom: 10%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.img-bottom {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.company-page-third-section-body.projects {
	flex-direction: column;
}

.project-list-container {
	display: flex;
	flex-wrap: wrap;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 20px #0000000d;
	padding: 5%;
	align-items: flex-start;
	justify-content: space-between;
	padding-left: 7%;
	padding-right: 7%;
}

.project-list-item {
	width: 45%;
	/* margin-left: 5%; */
	margin-bottom: 2%;
	margin-top: 2%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: normal;
}

.project-list-item-top {
	display: flex;
	justify-content: space-between;
	/* align-items: center; */
	margin-bottom: 5%;
}

.project-list-item-top-text-header {
	text-align: left;
	font: 15px/19px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
	font-weight: 200;
	margin-bottom: 5%;
}

.project-list-item-top-text-company {
	text-align: left;
	font: Bold 15px/30px Josefin Sans;
	letter-spacing: 0.18px;
	color: #062a4e;
	opacity: 1;
}

.project-list-item-top-image-wrapper {
	width: 20%;
}

.project-list-item-top-image-wrapper img {
	width: 100%;
}

.project-list-item-bottom-header {
	text-align: left;
	font: Bold 15px/30px Josefin Sans;
	letter-spacing: 0.18px;
	color: #062a4e;
	opacity: 1;
}

.project-list-item-bottom-list {
	text-align: left;
	font: 13px/23px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.company-page-third-section.careers {
	margin-top: 10%;
}

.company-page-third-section-body.careers {
	justify-content: space-between;
}

.career-card {
	display: flex;
	flex-direction: column;
	width: 30%;
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
}

.career-card-header {
	text-align: left;
	font: Bold 20px/35px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	/* background: #f9c4ba36 0% 0% no-repeat padding-box; */
	background: #f9c4ba2e 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	padding-left: 10%;
	padding-right: 10%;
	padding-top: 5%;
	padding-bottom: 5%;
}

.career-card-body {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
}

.career-card-body-text {
	text-align: left;
	letter-spacing: 0px;
	color: #062a4e;
	opacity: 1;
	margin-top: 10%;
	margin-bottom: 5%;
	margin-left: 10%;
	margin-right: 10%;
	font-family: Josefin Sans;
}

.career-card-body-button {
	text-decoration: none;
	color: #fc2a00;
	border: 1px solid #fc2a00;
	text-align: center;
	font: Bold 18px/25px Josefin Sans;
	background-color: white;
	letter-spacing: 0px;
	color: #fc2a00;
	padding-left: 10%;
	padding-right: 10%;
	padding-top: 5%;
	padding-bottom: 5%;
	margin-bottom: 10%;
	margin-top: 5%;
	cursor: pointer;
	align-self: flex-start;
	margin-left: 10%;
}

/* .career-card-body-button a {
	
} */

.career-card-body-button:focus {
	border: 1px solid #fc2a00;
	outline: none;
}

.project-list-item-bottom {
	/* height: 7rem; */
}

.simple-trans-main {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.simple-trans-main > div {
	will-change: transform, opacity, position;
	cursor: pointer;
	box-sizing: border-box;
	height: 70vh;
}

.slider-wrapper {
	position: relative;
	display: flex;
	align-items: center;
}

.left-arrow {
	position: absolute;
	left: -9%;
	cursor: pointer;
	width: 6%;
	transform: rotate(180deg);
	transition: all 0.3s ease-in-out;
}

.left-arrow:hover {
	/* background-color: #1fc6cd; */
}

.left-arrow img {
	width: 100%;
	transition: all 0.01s ease-in-out;
}

.right-arrow img {
	width: 100%;
	transition: all 0.01s ease-in-out;
}
.right-arrow:hover img {
	/* background-color: #1fc6cd;
	mix-blend-mode: darken; */
	filter: invert(5%) sepia(26%) saturate(5807%) hue-rotate(173deg)
		brightness(108%) contrast(92%);
}

.left-arrow:hover img {
	/* background-color: #1fc6cd; */
	/* background-blend-mode: multiply; */
	filter: invert(5%) sepia(26%) saturate(5807%) hue-rotate(173deg)
		brightness(108%) contrast(92%);
}

.right-arrow {
	position: absolute;
	right: -9%;
	cursor: pointer;
	width: 6%;
	transition: all 0.3s ease-in-out;
}

.right-arrow:hover {
}

.invisible {
	visibility: hidden;
}

.oem-logos {
	width: 100%;
}

.img .client-logos-img {
	width: 100%;
}

@media only screen and (max-width: 500px) {
	.company-page-first-section {
		height: auto;
		flex-direction: column-reverse;
		align-items: center;
	}
	.first-section-left h1 {
		font: normal normal bold 32px/34px Josefin Sans;
		letter-spacing: 0px;
		color: #fc2a00;
		margin-bottom: 7%;
	}

	.second-section-left {
		position: relative;
		top: 0;
		/* justify-content: center; */
		align-self: center;
		width: 90%;
	}

	.second-section-left-text-small {
		width: 50%;
	}

	.company-page-second-section {
		margin-top: 10%;
		flex-direction: column;
	}

	.first-section-left {
		width: 100%;
	}

	.first-section-left p {
		font: 18px/18px Josefin Sans;
	}

	.first-section-right {
		width: 60%;
		justify-content: center;
		align-items: flex-end;
		height: auto;
		bottom: 7rem;
		z-index: 2;
		margin-top: 35vh;
	}

	.first-section-right img {
		width: 100%;
		margin-top: 10%;
	}

	.second-section-right {
		align-self: center;
		width: 90%;
		margin-bottom: 10%;
	}

	.second-section-right > h3 {
		font: normal normal 600 22px/49px Josefin Sans;
	}

	.company-page-third-section-header-wrapper {
		width: 100%;
		box-sizing: border-box;
	}

	.company-page-third-section-header-wrapper h1 {
		font: normal normal bold 28px/49px Josefin Sans;
		letter-spacing: 0px;
		color: #fc2a00;
	}

	.company-page-third-section-header-wrapper h3 {
		font: normal normal 300 18px Josefin Sans;
		letter-spacing: 0px;
		color: #1fc6cd;
	}

	.company-page-third-section-body {
		flex-direction: column;
		align-items: center;
	}

	.company-page-third-section-left {
		width: 75%;
		/* margin-left: 5%; */
	}

	.company-page-third-section-right {
		width: 100%;
		margin-bottom: 15%;
	}

	.company-third-section-right-header {
		font: normal normal 600 22px/30px Josefin Sans;
		letter-spacing: 0px;
		color: #062a4e;
		width: fit-content;
		margin-top: 15%;
		margin-bottom: 7%;
	}

	.company-third-section-background {
		width: 45%;
	}

	.company-third-section-qoute-text {
		font: normal normal normal 12px/16px Josefin Sans;
		letter-spacing: 0px;
		color: #062a4e;
		width: 65%;
	}

	.company-page-fourth-section-sub.left-top {
		width: 70%;
		padding-bottom: 7%;
		padding-top: 7%;
		padding-left: 5%;
		padding-right: 5%;
		top: -5rem;
	}

	.company-page-fourth-section-sub-header {
		text-align: left;
		font: normal normal bold 18px Josefin Sans;
		letter-spacing: 0.18px;
		color: #062a4e;
		margin-bottom: 5%;
	}

	.company-page-fourth-section-sub-text {
		text-align: left;
		font: normal normal normal 12px/20px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
	}

	.company-page-fourth-section-image {
		width: 90%;
	}

	.company-page-fourth-section-sub.right-bottom {
		bottom: -5rem;
		width: 70%;
		padding-bottom: 7%;
		padding-top: 7%;
		padding-left: 5%;
		padding-right: 5%;
	}

	.company-page-fourth-section {
		margin-top: 25%;
		margin-bottom: 25%;
	}

	.company-page-fourth-section-sub.right-bottom div {
		text-align: right;
	}

	.company-page-third-section-header-wrapper.clients {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		padding-left: 5%;
		box-sizing: border-box;
		font: normal normal bold 28px/49px Josefin Sans;
		letter-spacing: 0px;
		color: #fc2a00;
	}

	.company-page-third-section-body-left {
		width: 90%;
		right: 0;
		display: flex;
		align-items: center;
		margin-top: 5%;
	}

	.company-page-third-section-body-left__text {
		margin-bottom: 10%;
		white-space: nowrap;
	}

	.project-list-container {
		flex-direction: column;
	}

	.project-list-item {
		width: 100%;
		margin-bottom: 15%;
	}

	.career-card {
		width: 90%;
	}

	.company-page-third-section-body.careers {
		margin-bottom: 10%;
	}

	.career-card-body-button {
		white-space: nowrap;
		width: fit-content;
	}

	.career-card-header {
		font: Bold 20px/28px Josefin Sans;
	}

	.right-arrow,
	.left-arrow {
		display: none;
	}

	.simple-trans-main > div {
		height: auto;
	}
}

@media only screen and (min-width: 1224px) {
	.first-section-left p {
		font: 17px/18px Josefin Sans;
	}

	.second-section-left-text-small {
		font: 17px/18px Josefin Sans;
	}

	.company-third-section-right-header {
		font: 25px Josefin Sans;
	}

	.company-third-section-qoute-text {
		font: 14px/19px Josefin Sans;
	}

	.company-third-section-qoute-text.name {
		font-size: 23px;
	}

	.company-third-section-qoute-text.italic {
		font: Italic 14px/15px Josefin Sans;
	}

	.company-page-fourth-section-sub-header {
		font: Bold 14px/30px Josefin Sans;
	}

	.company-page-fourth-section-sub-text {
		font: 14px/15px Josefin Sans;
	}

	.project-list-item-bottom-list {
		font: 15px/23px Josefin Sans;
	}

	.project-list-item-bottom-header {
		font: Bold 17px/30px Josefin Sans;
	}

	.project-list-item-top-text-company {
		font: Bold 17px/30px Josefin Sans;
	}

	.project-list-item-top-text-header {
		font: 17px/19px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
		font-weight: 200;
	}
}

@media only screen and (min-width: 1824px) {
	/* Styles */

	.first-section-left h1 {
		font: normal normal bold 90px/109px Josefin Sans;
	}

	.first-section-left p {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.second-section-left-text-small {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.second-section-right > h3 {
		font: normal normal 600 40px/49px Josefin Sans;
	}

	.company-page-third-section-header-wrapper h1 {
		font: normal normal bold 90px/49px Josefin Sans;
	}

	.company-page-third-section-header-wrapper h3 {
		font: normal normal normal 35px Josefin Sans;
	}

	.company-third-section-right-header {
		font: normal normal 600 40px/86px Josefin Sans;
	}

	.company-third-section-qoute-text {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.company-page-fourth-section-sub-header {
		font: normal normal bold 18px/40px Josefin Sans;
	}

	.company-page-fourth-section-sub-text {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.project-list-item-bottom-list {
		font: normal normal normal 18px/23px Josefin Sans;
	}
}
