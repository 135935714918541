.products-page-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.products-page-content-wrapper {
	width: 70%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin-bottom: 5%;
}

.products-filter-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 5%;
	margin-bottom: 5%;
}

.products-filter-item {
	font: Bold 14px/25px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
	cursor: pointer;
	display: flex;
	height: 3rem;
	align-items: center;
	/* padding: 2%; */
	padding-left: 2%;
	box-sizing: border-box;
	/* width: 10%; */
	padding-right: 2%;
	transition: all 0.3s ease-in-out;
}

.products-filter-item:hover {
	color: #1fc6cd;
}

.products-filter-item.active {
	background-color: #caeef445;
	color: #1fc6cd;
	border-radius: 0.3rem;
}

.products-page-container.products {
	justify-content: flex-start;
	flex-wrap: wrap;
	position: relative;
}
/* Large screens ----------- */
@media only screen and (min-width: 1224px) {
	.products-filter-item {
		font: Bold 18px/25px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
		cursor: pointer;
		display: flex;
		height: 3rem;
		align-items: center;
		/* padding: 2%; */
		padding-left: 2%;
		box-sizing: border-box;
		/* width: 10%; */
		padding-right: 2%;
		transition: all 0.3s ease-in-out;
	}
}

@media only screen and (max-width: 500px) {
	.products-filter-list {
		overflow-x: scroll;
		border-bottom: 0.75px solid #70707038;
		padding-bottom: 5%;
	}

	.products-page-content-wrapper {
		width: 100%;
	}

	.products-filter-item {
		background: #70707021 0% 0% no-repeat padding-box;
		border: 1px solid #70707029;
		border-radius: 8px;
		margin-left: 5%;
		text-align: center;
		font: normal normal 300 16px/25px Josefin Sans;
		letter-spacing: 0px;
		color: #707070;
		white-space: nowrap;
		transition: all 0.3s ease-in-out;
		width: fit-content;
	}

	.products-page-container.products {
		flex-direction: column;
	}

	.top-product-item-wrapper.product.page {
		margin-right: 0%;
		width: 90%;
	}

	/* .top-product-item-name.product {
		font: normal normal 20px Josefin Sans;
	} */

	.top-product-item-price-range.product {
		font: normal normal 18px Tahoma;
	}

	.products-filter-item.active {
		background-color: #caeef445;
		color: #1fc6cd;
		border-radius: 0.3rem;
		font-weight: bold;
	}

	.top-product-item-img.product {
		width: 100%;
	}

	.top-product-item-name {
		font: bold 24px/28px Josefin Sans;
	}
}
