.top-product-section.product-detail {
	width: 100%;
}

.product-details-image-wrapper {
	padding-top: 5%;
	padding-bottom: 5%;
	box-sizing: border-box;
	background-color: white;
	width: 30%;
	padding-left: 2%;
	padding-right: 2%;
}

.product-details-image-wrapper img {
	width: 100%;
}

.product-details-right-details {
	width: 50%;
	margin-left: 5%;
}

.product-details-name {
	font: 19px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #000000;
	text-align: start;
	margin-top: 5%;
	text-decoration: none;
	cursor: pointer;
}

.product-details-price-select-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 2%;
	margin-top: 2%;
	align-items: center;
}

.product-details-price {
	font: 18px Tahoma;
	letter-spacing: 0px;
	color: #fc2a00;
	opacity: 1;
}

.react-select__placeholder {
	font: Bold 14px Josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.spec-selector {
	width: 30%;
}

.product-details-summary {
	text-align: left;
	font: 14px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
	margin-bottom: 10%;
	margin-top: 5%;
}

.product-details-get-quote {
	width: 35%;
	height: 3.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fc2a00 0% 0% no-repeat padding-box;
	font: Bold 12px/25px Josefin Sans;
	color: white;
	text-decoration: none;
}

.react-select__indicator-separator {
	display: none;
}

.product-detail-more {
	flex-direction: column;
}

.top-product-title.product-detail {
	margin-left: 0px;
	font: 15px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #000000;
	text-align: start;
	margin-top: 5%;
	text-decoration: none;
	margin-bottom: 2%;
}

.des-details {
	padding-left: 2%;
	padding-right: 2%;
	padding-top: 3%;
	padding-bottom: 3%;
	background-color: white;
	text-align: left;
	font: 14px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.services-content-item.product-details {
	justify-content: flex-start;
}

.des-details-two {
	display: block;
}

@media only screen and (max-width: 500px) {
	.services-content-item.product-details {
		margin-top: 10%;
		flex-direction: column;
	}

	.product-details-price-select-wrapper {
		flex-direction: column;
	}

	.product-details-right-details {
		width: 90%;
		margin-left: 0;
		align-items: center;
		display: flex;
		flex-direction: column;
	}

	.product-details-image-wrapper {
		width: 90%;
		box-shadow: 0px 3px 96px #00000019;
	}

	.product-details-name {
		text-align: center;
		margin-bottom: 5%;
		margin-top: 10%;
		font: normal normal bold 1.3rem/28px Josefin Sans;
	}

	.product-details-price {
		font: normal normal normal 20px/39px Tahoma;
		margin-bottom: 5%;
	}

	.spec-selector {
		width: 70%;
		min-width: 10rem;
		margin-bottom: 10%;
	}

	.react-select__placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}

	.des-details-two {
		display: none;
	}

	.des-details {
		width: 90%;
		padding-left: 5%;
		padding-right: 5%;
		box-sizing: border-box;
	}
	.product-detail-more {
		flex-direction: column !important;
	}

	.top-product-title-link-wrapper {
		width: 90%;
		justify-content: center;
		margin-bottom: 5%;
	}

	.des-details-one {
		text-align: left;
	}

	.product-details-summary {
		padding-top: 5%;
		padding-bottom: 5%;
		background-color: white;
		text-align: left;
		font: 14px/18px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
		width: 100%;
		padding-left: 5%;
		padding-right: 5%;
		box-sizing: border-box;
	}

	.top-product-title.product-detail {
		justify-content: center;
		margin-top: 10%;
		font: normal normal bold 16px/23px Josefin Sans;
		letter-spacing: 0.32px;
		color: #000000;
	}

	.top-product-title-link-wrapper.more {
		width: 100%;
	}
}
