.nav-container {
	background: #062a4e 0% 0% no-repeat padding-box;
	display: flex;
	justify-content: center;
	position: sticky;
	top: 0;
	z-index: 4;
}

.nav-wrapper {
	height: 70px;
	width: 90%;
	max-width: 1440px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* UI Properties */
}

.nav-links-brand-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 35%;
	min-width: 25rem;
}

.nav-brand-wrapper {
	width: 10%;
}

.nav-brand-wrapper svg {
	width: 100%;
}

.nav-links-wrapper {
	width: 80%;
	display: flex;
	justify-content: space-between;
}

.nav-bar-item {
	text-decoration: none;
	font: 14px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
	transition: all ease-in-out 0.3s;
}

.nav-bar-item:hover {
	color: #caeef4;
}

.nav-bar-item.active {
	color: #1fc6cd;
}

.nav-logo-wrapper {
	width: 10%;
	margin-left: 3%;
}

.nav-logo-wrapper svg {
	width: 100%;
}

.nav-logo-wrapper.mobile {
	display: none !important;
}

@media only screen and (max-width: 500px) {
	.nav-links-wrapper {
		display: none;
	}

	.nav-brand-wrapper {
		width: 100%;
	}

	.nav-links-brand-wrapper {
		min-width: 10%;
		display: flex;
		justify-content: flex-start;
		width: 15%;
	}

	.nav-logo-wrapper {
		display: none;
	}

	.nav-logo-wrapper.mobile {
		display: flex !important;
	}
}

@media only screen and (min-width: 1224px) {
	/* Styles */
	.nav-bar-item {
		font: normal normal normal 14px/25px Josefin Sans;
	}
}

@media only screen and (min-width: 1824px) {
	/* Styles */
	.nav-bar-item {
		font: normal normal normal 16px/25px Josefin Sans;
	}
}
