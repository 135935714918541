@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600;700&display=swap);
html,
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	width: 100%;
}

#root {
	height: 100%;
	width: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.products-page-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.products-page-content-wrapper {
	width: 70%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin-bottom: 5%;
}

.products-filter-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 5%;
	margin-bottom: 5%;
}

.products-filter-item {
	font: Bold 14px/25px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
	cursor: pointer;
	display: flex;
	height: 3rem;
	align-items: center;
	/* padding: 2%; */
	padding-left: 2%;
	box-sizing: border-box;
	/* width: 10%; */
	padding-right: 2%;
	transition: all 0.3s ease-in-out;
}

.products-filter-item:hover {
	color: #1fc6cd;
}

.products-filter-item.active {
	background-color: #caeef445;
	color: #1fc6cd;
	border-radius: 0.3rem;
}

.products-page-container.products {
	justify-content: flex-start;
	flex-wrap: wrap;
	position: relative;
}
/* Large screens ----------- */
@media only screen and (min-width: 1224px) {
	.products-filter-item {
		font: Bold 18px/25px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
		cursor: pointer;
		display: flex;
		height: 3rem;
		align-items: center;
		/* padding: 2%; */
		padding-left: 2%;
		box-sizing: border-box;
		/* width: 10%; */
		padding-right: 2%;
		transition: all 0.3s ease-in-out;
	}
}

@media only screen and (max-width: 500px) {
	.products-filter-list {
		overflow-x: scroll;
		border-bottom: 0.75px solid #70707038;
		padding-bottom: 5%;
	}

	.products-page-content-wrapper {
		width: 100%;
	}

	.products-filter-item {
		background: #70707021 0% 0% no-repeat padding-box;
		border: 1px solid #70707029;
		border-radius: 8px;
		margin-left: 5%;
		text-align: center;
		font: normal normal 300 16px/25px Josefin Sans;
		letter-spacing: 0px;
		color: #707070;
		white-space: nowrap;
		transition: all 0.3s ease-in-out;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.products-page-container.products {
		flex-direction: column;
	}

	.top-product-item-wrapper.product.page {
		margin-right: 0%;
		width: 90%;
	}

	/* .top-product-item-name.product {
		font: normal normal 20px Josefin Sans;
	} */

	.top-product-item-price-range.product {
		font: normal normal 18px Tahoma;
	}

	.products-filter-item.active {
		background-color: #caeef445;
		color: #1fc6cd;
		border-radius: 0.3rem;
		font-weight: bold;
	}

	.top-product-item-img.product {
		width: 100%;
	}

	.top-product-item-name {
		font: bold 24px/28px Josefin Sans;
	}
}

.top-product-section.product-detail {
	width: 100%;
}

.product-details-image-wrapper {
	padding-top: 5%;
	padding-bottom: 5%;
	box-sizing: border-box;
	background-color: white;
	width: 30%;
	padding-left: 2%;
	padding-right: 2%;
}

.product-details-image-wrapper img {
	width: 100%;
}

.product-details-right-details {
	width: 50%;
	margin-left: 5%;
}

.product-details-name {
	font: 19px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #000000;
	text-align: start;
	margin-top: 5%;
	text-decoration: none;
	cursor: pointer;
}

.product-details-price-select-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 2%;
	margin-top: 2%;
	align-items: center;
}

.product-details-price {
	font: 18px Tahoma;
	letter-spacing: 0px;
	color: #fc2a00;
	opacity: 1;
}

.react-select__placeholder {
	font: Bold 14px Josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.spec-selector {
	width: 30%;
}

.product-details-summary {
	text-align: left;
	font: 14px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
	margin-bottom: 10%;
	margin-top: 5%;
}

.product-details-get-quote {
	width: 35%;
	height: 3.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fc2a00 0% 0% no-repeat padding-box;
	font: Bold 12px/25px Josefin Sans;
	color: white;
	text-decoration: none;
}

.react-select__indicator-separator {
	display: none;
}

.product-detail-more {
	flex-direction: column;
}

.top-product-title.product-detail {
	margin-left: 0px;
	font: 15px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #000000;
	text-align: start;
	margin-top: 5%;
	text-decoration: none;
	margin-bottom: 2%;
}

.des-details {
	padding-left: 2%;
	padding-right: 2%;
	padding-top: 3%;
	padding-bottom: 3%;
	background-color: white;
	text-align: left;
	font: 14px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.services-content-item.product-details {
	justify-content: flex-start;
}

.des-details-two {
	display: block;
}

@media only screen and (max-width: 500px) {
	.services-content-item.product-details {
		margin-top: 10%;
		flex-direction: column;
	}

	.product-details-price-select-wrapper {
		flex-direction: column;
	}

	.product-details-right-details {
		width: 90%;
		margin-left: 0;
		align-items: center;
		display: flex;
		flex-direction: column;
	}

	.product-details-image-wrapper {
		width: 90%;
		box-shadow: 0px 3px 96px #00000019;
	}

	.product-details-name {
		text-align: center;
		margin-bottom: 5%;
		margin-top: 10%;
		font: normal normal bold 1.3rem/28px Josefin Sans;
	}

	.product-details-price {
		font: normal normal normal 20px/39px Tahoma;
		margin-bottom: 5%;
	}

	.spec-selector {
		width: 70%;
		min-width: 10rem;
		margin-bottom: 10%;
	}

	.react-select__placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}

	.des-details-two {
		display: none;
	}

	.des-details {
		width: 90%;
		padding-left: 5%;
		padding-right: 5%;
		box-sizing: border-box;
	}
	.product-detail-more {
		flex-direction: column !important;
	}

	.top-product-title-link-wrapper {
		width: 90%;
		justify-content: center;
		margin-bottom: 5%;
	}

	.des-details-one {
		text-align: left;
	}

	.product-details-summary {
		padding-top: 5%;
		padding-bottom: 5%;
		background-color: white;
		text-align: left;
		font: 14px/18px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
		width: 100%;
		padding-left: 5%;
		padding-right: 5%;
		box-sizing: border-box;
	}

	.top-product-title.product-detail {
		justify-content: center;
		margin-top: 10%;
		font: normal normal bold 16px/23px Josefin Sans;
		letter-spacing: 0.32px;
		color: #000000;
	}

	.top-product-title-link-wrapper.more {
		width: 100%;
	}
}

.services-section-container {
	width: 100%;
	display: flex;
	justify-content: center;
	background: #f7f7f7 0% 0% no-repeat padding-box;
}

.services-section-content-wrapper {
	width: 78%;
	display: flex;
	flex-direction: column;
	margin-bottom: 5%;
}

.services-content-item {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	margin-top: 5%;
}

.services-content-left-group {
	width: 35%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.services-content-left-header {
	font: 22px/42px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #062a4e;
	margin-bottom: 10%;
}

.services-content-left-text {
	font: 13px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
	margin-bottom: 10%;
}

.services-content-right-group {
	width: 55%;
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	align-self: flex-end;
}

.services-content-right-worker-svg {
	display: flex;
	width: 70%;
	position: absolute;
	left: 10%;
}

.services-content-right-background-svg {
	width: 70%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.services-content-right-background-svg svg {
	width: 100%;
	height: 100%;
}

.services-content-right-worker-svg svg {
	width: 100%;
	height: 100%;
}

.services-content-right-worker-svg img {
	width: 75%;
}

.services-content-item.reverse {
	flex-direction: row-reverse;
}

.services-content-left-group.reverse {
	align-items: flex-end;
}

.services-content-left-text.reverse {
	text-align: right;
}

.services-content-right-group.reverse .services-content-right-worker-svg {
	right: 10%;
	left: auto;
	display: flex;
	justify-content: flex-end;
}

.services-content-right-group.reverse {
	justify-content: flex-start;
}

.services-content-right-background-img img {
	width: 100%;
	height: 100%;
}

.services-content-right-background-img {
	display: flex;
	position: absolute;
	left: 10%;
}

.services-content-left-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60%;
	height: 3rem;
	font: Bold 12px/25px Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	border: 1px solid #fc2a00;
	background-size: 200% 200%;
	transition: all 0.3s;
	background-image: linear-gradient(to right, #fc2a00 50%, white 0);
	background-position: right;
	text-decoration: none;
	cursor: pointer;
}

.services-content-left-button:hover {
	background-position: left;
}

.services-content-left-button:hover {
	color: #ffffff;
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 500px) {
	.services-section-content-wrapper {
		width: 100%;
	}

	.services-content-item {
		flex-direction: column-reverse;
	}

	.services-content-right-group {
		align-self: flex-end;
		width: 70%;
	}

	.services-content-left-group {
		width: 85%;
	}

	.services-content-item.reverse {
		flex-direction: column-reverse;
	}

	.services-content-right-group.reverse {
		align-self: flex-start;
	}

	.services-content-left-header {
		margin-bottom: 5%;
		font: 22px/30px Josefin Sans;
	}

	.reverse.last {
		margin-bottom: 10%;
	}
}

.footer-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 271px;
	/* UI Properties */
	background: #062a4e 0% 0% no-repeat padding-box;
}

.footer-wrapper {
	width: 90%;
	max-width: 1440px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.footer-link-brand-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 60%;
	min-width: 25rem;
}

.nav-brand-wrapper.footer {
	width: 10%;
}

.footer-link-wrapper {
	display: flex;
	justify-content: space-between;
	width: 87%;
}

.footer-link-list {
	font: 10px/20px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
	text-decoration: none;
}

.footer-link-list-title {
	font: Bold 12px/25px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
	margin-bottom: 10%;
}

.moblie-footer-icons {
	display: none;
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 500px) {
	.footer-wrapper {
		flex-direction: column;
	}

	.nav-brand-wrapper.footer {
		display: none;
	}

	.nav-logo-wrapper {
		display: none;
	}

	.moblie-footer-icons {
		display: flex;
		justify-content: space-between;
		width: 100%;
		border-top: 1px solid #ffffff;
		margin-top: 5%;
		padding-top: 5%;
	}

	.footer-link-brand-wrapper {
		min-width: 100%;
	}

	.footer-container {
		padding-top: 5%;
		padding-bottom: 5%;
		height: auto;
	}

	.footer-link-wrapper {
		width: 100%;
	}

	.nav-brand-wrapper.footer.mobile {
		display: flex;
	}

	.nav-logo-wrapper.footer {
		display: flex;
		width: 20%;
	}

	.footer-link-list {
		font: 8px/20px Josefin Sans;
	}
}

@media only screen and (min-width: 1224px) {
	/* Styles */
}

@media only screen and (min-width: 1824px) {
	/* Styles */
}

.page-container {
	width: 100%;
	display: flex;
	justify-content: center;
	background: #f7f7f7 0% 0% no-repeat padding-box;
	flex-direction: column;
	align-items: center;
}

.jumbotron-section {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
	/* max-width: 1440px; */
	z-index: 1;
}

.jumbotron-section-content {
	width: 70%;
	position: relative;
}

.jumbotron-section-content img {
	width: 100%;
}

.header-sub-section {
	width: 90%;
	display: flex;
	justify-content: flex-start;
	position: relative;
	/* max-width: 1440px; */
}

.header-sub-section-content {
	background: #f5feff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 46px #00000012;
	width: 48%;
	padding: 5%;
	margin-left: 7%;
	box-sizing: border-box;
	margin-top: -0.3%;
}

.header-sub-section-large-text {
	font: Bold 4rem Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	margin-bottom: 3%;
}

.header-sub-section-small-text {
	font: 1.3rem Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.header-sub-section-image {
	position: absolute;
	top: -55%;
	right: 5%;
	width: 32%;
	z-index: 3;
}

.header-sub-section-image img {
	width: 100%;
}

.jumbotron-section-content-text {
	font: Bold 3.5rem Josefin Sans;
	letter-spacing: 0px;
	color: #1fc6cd;
	position: absolute;
	left: 11%;
	top: 15%;
}

.jumbotron-section-content-text span {
	color: #fc2a00;
}

.special-offers-wrapper {
	position: relative;
	/* top: 2%; */
	overflow: hidden;
	right: 5%;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	/* height: 45%; */
	background: url(/static/media/Group9337.adb40253.png) 30% 0% no-repeat padding-box;
	background-size: cover;
}

.special-offers-content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.special-offers-content h2 {
	font: 1.3rem Josefin Sans;
	/* font-weight: 300; */
	letter-spacing: 0px;
	color: #062a4e;
	text-align: center;
	margin-bottom: 10%;
	/* margin-top: 20%; */
}

.special-offers-content div {
	font: 2.5rem Josefin Sans;
	font-weight: 600;
	letter-spacing: -3.15px;
	color: #fff;
	text-align: center;
	margin-bottom: 5%;
}
.special-offers-content p {
	font: 0.81rem Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	text-align: center;
}
.special-offers-content a {
	display: flex;
	width: 40%;
	text-decoration: none;
	justify-content: center;
	align-items: center;
	background: #fc2a00 0% 0% no-repeat padding-box;
	font: Bold 12px/39px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
	margin-top: 10%;
	text-align: center;
	transition: all ease-in-out 0.3s;
	/* margin-left: 30%; */
	/* margin-bottom: 20%; */
}

.special-offers-content a:hover {
	background: #062a4e 0% 0% no-repeat padding-box;
	color: #caeef4;
}

.top-product-section {
	width: 78%;
	display: flex;
	flex-direction: column;
	margin-top: 5%;
	margin-bottom: 7%;
}

.top-product-title-link-wrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.top-product-title {
	font: 20px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #062a4e;
	margin-left: 3%;
}

.top-product-link {
	font: 12px/49px Josefin Sans;
	letter-spacing: 0px;
	text-decoration: none;
	color: #000000;
	transform: scale(1);
	margin-right: 3%;
	transition: all ease-in-out 0.3s;
	display: flex;
	justify-content: space-between;
	width: 12%;
	align-items: center;
}

.exit-group {
	display: flex;
}

.top-product-link:hover {
	transform: scale(1.03);
}

.top-product-link .exit-group .exit2 {
	margin-left: 0%;
	transition: all ease-in-out 0.3s;
}

.top-product-link:hover .exit-group .exit2 {
	margin-left: 15%;
}

.top-product-link .exit-group .exit2.blog {
	margin-left: 0%;
	transition: all ease-in-out 0.3s;
	position: relative;
	right: 35%;
	margin-bottom: 20%;
}

.top-product-link:hover .exit-group .exit2.blog {
	right: 32%;
	margin-bottom: 35%;
}

.top-product-items-list {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	background: #ffffff 0% 0% no-repeat padding-box;
	height: 46vh;
	min-height: 19rem;
}

.top-product-item-wrapper {
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0%;
	padding-left: 3%;
	padding-right: 3%;
	padding-bottom: 3%;
	box-sizing: border-box;
	margin-top: 4%;
	transform: scale(1);
	transition: all ease-in-out 0.3s;
}

.top-product-item-wrapper.blog {
	padding-bottom: 3%;
	text-decoration: none;
}

.top-product-item-wrapper .top-product-item-button {
	/* width: 32%; */
	display: flex;
	flex-direction: column;
	align-items: center;
}

.top-product-item-wrapper:hover {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 96px #00000019;
	/* height: 42vh; */
	/* min-height: 20rem; */
	transform: scale(1.03);
	/* justify-content: space-evenly; */
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.top-product-item-wrapper.product:hover {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 96px #00000019;
	height: auto;
	min-height: 19rem;
	transform: scale(1.03);
	justify-content: space-evenly;
}

.top-product-item-wrapper.product {
	margin-right: 3%;
}

.top-product-item-wrapper:hover .top-product-item-button {
	opacity: 1;
}

.top-product-item-wrapper img {
	width: 100%;
}

.top-product-item-name {
	font: 15px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #000000;
	text-align: center;
	margin-top: 5%;
	text-decoration: none;
	cursor: pointer;
}

.top-product-item-price-range {
	font: 10px Josefin Sans;
	letter-spacing: 0px;
	color: #767676;
	margin-top: 5%;
}

.top-product-item-wrapper .top-product-item-button {
	opacity: 0;
	transition: all ease-out 0.2s;
	margin-bottom: 2%;
}

.top-product-item-button {
	width: 40%;
	text-decoration: none;
	justify-content: center;
	align-items: center;
	background: #fc2a00 0% 0% no-repeat padding-box;
	font: Bold 12px/39px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
	margin-top: 5%;
	transition: all ease-in-out 0.3s;
}

.top-product-item-button:hover {
	background: #062a4e 0% 0% no-repeat padding-box;
	color: #caeef4;
}

.top-services-section {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 5%;
}

.top-services-section-wrapper {
	width: 78%;
	/* margin-bottom: 30%; */
}

.top-service-item-list {
	display: flex;
	justify-content: space-evenly;
	margin-top: 5%;
}

.top-service-item {
	width: 25%;
	position: relative;
	display: flex;
	/* height: 22%; */
	justify-content: center;
	align-items: center;
}

/* .top-service-item svg {
	width: 100%;
	position: absolute;
	top: 0;
	z-index: -1;
} */

.top-service-item-background-image {
	width: 100%;
	position: absolute;
	/* top: 0px; */
	display: flex;
	/* justify-content: center; */
	align-items: center;
	/* height: 100%; */
	z-index: 1;
	transform: scale(0);
	transition: all ease-in-out 0.3s;
}

.top-service-item:hover .top-service-item-background-image {
	transform: scale(1.4);
}

.top-service-item-background-image img {
	width: 100%;
	height: 100%;
}

.top-service-item-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* height: 100%; */
	width: 95%;
	z-index: 2;
}

.top-service-item-inner-svgs {
	position: relative;
	width: 100%;
	display: flex;
	/* justify-content: center; */
	align-items: flex-end;
	transition: all ease-in-out 0.3s;
}

.top-service-item-inner-background {
	width: 100%;
	/* margin-top: 40%; */
	/* height: 100%; */
	display: flex;
	justify-content: center;
	border-bottom: 3px solid #8dd8e0;
}

.top-service-item-inner-background svg {
	width: 100%;
	height: 100%;
	/* border-bottom: 1px solid blue; */
}

.top-service-item-inner-worker-svg {
	position: absolute;
	left: 0;
	width: 30%;
	margin-left: 15%;
	transition: all ease-in-out 0.3s;
}

.top-service-item-inner-worker-svg svg {
	width: 100%;
	height: 100%;
}

.top-service-item:hover .top-service-item-content .top-service-item-text,
.top-service-item:hover .top-service-item-content .top-service-item-link {
	opacity: 1;
}

.top-service-item-title {
	font: 18px/39px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #0d0d0d;
	margin-top: 5%;
}

.top-service-item-text {
	opacity: 0;
	margin-top: 5%;
	font: 11px Josefin Sans;
	letter-spacing: 0px;
	color: #0d0d0d;
	text-align: center;
	transition: all ease-in-out 0.3s;
}

.top-service-item-link {
	margin-top: 10%;
	opacity: 0;
	transition: all ease-in-out 0.3s;
	font: Bold 14px/39px Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	cursor: pointer;
	text-decoration: none;
}

.top-service-item:hover .top-service-item-content .top-service-item-inner-svgs {
	transform: scale(1.1);
}

.top-service-item:hover
	.top-service-item-content
	.top-service-item-inner-svgs
	.top-service-item-inner-worker-svg {
	margin-left: 10%;
}

.renewable-energy-section {
	width: 100%;
	display: flex;
	justify-content: center;
	/* margin-bottom: 5%; */
}

.renewable-energy-wrapper {
	width: 78%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
}

.renewable-energy-title-wrapper {
	width: 100%;
	font: 20px/49px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #062a4e;
	margin-left: 3%;
	display: flex;
	justify-content: flex-start;
}

.renewable-energy-content {
	display: flex;
	justify-content: space-between;
	margin-top: 2%;
}

.renewable-energy-section-image {
	width: 40%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.renewable-energy-section-image img {
	width: 95%;
	mix-blend-mode: darken;
	margin-top: 5%;
	margin-bottom: 5%;
}

.renewable-packages-container {
	width: 50%;
	background: #ffffff 0% 0% no-repeat padding-box;
	padding-top: 5%;
	padding-bottom: 5%;
	padding-left: 5%;
	padding-right: 5%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.top-product-item-price-range.blog {
	opacity: 0;
	margin-top: 5%;
	font: 10px Josefin Sans;
	letter-spacing: 0px;
	color: #0d0d0d;
	text-align: center;
	transition: all ease-in-out 0.3s;
}

.top-product-item-price-range.blog ~ .top-product-item-button {
	background: none;
	color: #1fc6cd;
}

.renewable-packages-title {
	text-align: left;
	font: Bold 25px/49px Josefin Sans;
	letter-spacing: 0px;
}

.renewable-packages-title span {
	color: #1fc6cd;
}

.renewable-packages-text {
	text-align: left;
	font: 12px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.renewable-packages {
	display: flex;
	justify-content: flex-start;
	font: Bold 20px/39px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
}

.renewable-packages ul {
	list-style: none;
	-webkit-padding-start: 0px;
	        padding-inline-start: 0px;
}

.renewable-packages ul li::before {
	content: "\2022";
	color: #caeef4;
	font-weight: bold;
	display: inline-block;
	width: 1em;

	/* margin-left: -1em; */
}

.renewable-packages ul:last-child {
	-webkit-padding-start: 40px;
	        padding-inline-start: 40px;
}

.renewable-packages-container .top-product-item-button {
	width: 30%;
	display: flex;
	text-decoration: none;
	justify-content: center;
	align-items: center;
	margin-top: 0;
}

.top-product-item-wrapper:hover .top-product-item-price-range.blog {
	opacity: 0;
}

.endorsement-section {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: 5%;
}

.endorsement-content-wrapper {
	width: 78%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.endorsement-text {
	width: 60%;
	font: Italic 18px/24px Josefin Sans;
	font-weight: 300;
	letter-spacing: 0px;
	color: #000000;
	text-align: center;
}

.endorsement-image-title-wrapper {
	width: 20%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.endorsement-image-title-wrapper img {
	width: 40%;
}

.endorsement-name {
	font: 20px/14px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
	margin-bottom: 3%;
	margin-top: 10%;
}

.endorsement-title {
	font: Italic 13px/24px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.top-service-item-inner-worker-svg.installation {
	margin-left: -5%;
	width: 80%;
	/* right: 6px; */
	/* margin-bottom: 4rem; */
}

.top-service-item-inner-worker-svg.installation img {
	width: 100%;
}

.top-service-item-inner-hanging.installation {
	position: absolute;
	left: -16%;
	bottom: 0;
	margin-bottom: 15%;
	width: 20%;
	transition: all ease-in-out 0.3s;
}

.top-service-item-inner-hanging.installation img {
	width: 100%;
}

.top-service-item-inner-background.installation {
	border-bottom: none;
	align-items: flex-start;
}

.top-service-item-inner-background.installation img {
	width: 100%;
}

/* .top-service-item-inner-svgs.installation {
	margin-top: 10%;
} */

.top-service-item:hover
	.top-service-item-content
	.top-service-item-inner-svgs
	.top-service-item-inner-worker-svg.installation {
	margin-left: -5%;
}

.top-service-item:hover
	.top-service-item-content
	.top-service-item-inner-svgs.installation {
	/* margin-top: 5%; */
	transform: scale(1.08);
}

.top-service-item:hover
	.top-service-item-content
	.top-service-item-inner-svgs.installation
	.top-service-item-inner-hanging.installation {
	margin-bottom: 5%;
}

/* .top-service-item-inner-hanging.installation {
	transition: all ease-in-out 0.3s;
} */

/* .top-service-item:hover .top-service-item-background-image.installation {
	transform: scale(1.4);
} */

.top-service-item-background-image.installation {
	left: -5%;
}

.top-service-item-content-img {
}

.top-service-item-content-img img {
	width: 100%;
}

@media only screen and (max-width: 500px) {
	.top-product-item-name {
		font: normal normal bold 1.3rem/28px Josefin Sans;
	}
	.top-product-title-link-wrapper {
		width: 90%;
		justify-content: center;
		margin-bottom: 5%;
	}

	.top-product-link {
		width: 30%;
	}

	.top-service-item-background-image.installation {
		left: 0;
	}

	/* .top-product-title {
		margin-left: 0%;
	}

	.top-product-link {
		margin-right: 0%;
	} */

	.top-product-item-price-range {
		font: normal normal normal 20px/39px Tahoma;
		letter-spacing: 0px;
		color: #000000;
	}

	.top-product-items-list {
		flex-direction: column;
		height: auto;
		align-items: center;
		background: none;
	}

	.top-product-item-wrapper {
		width: 90%;
		background: white;
	}

	.top-product-item-wrapper .top-product-item-button {
		opacity: 1;
		margin-bottom: 5%;
	}

	.jumbotron-section-content img {
		width: 100%;
	}

	.jumbotron-section-content-text {
		text-align: left;
		font: normal normal bold 28px/34px Josefin Sans;
	}

	.jumbotron-section-content {
		width: 100%;
	}

	.special-offers-wrapper {
		position: absolute;
		top: 80%;
		width: 90%;
		align-self: center;
		padding: 0;
		height: 19rem;
		justify-content: center;
	}

	.special-offers-content h2 {
		margin-bottom: 4%;
		margin-top: 5%;
	}

	.special-offers-content a {
		width: 50%;
		height: 3rem;
		margin-top: 5%;
	}

	.special-offers-content p {
		font: 0.9rem Josefin Sans;
	}

	.header-sub-section {
		margin-top: 70%;
		width: 100%;
	}

	.header-sub-section-image {
		width: 50%;
		top: -20%;
	}

	.header-sub-section-content {
		margin-top: 20%;
		width: 100%;
		margin-left: 0;
		padding-top: 10%;
		padding-bottom: 10%;
		justify-content: center;
		padding-left: 7%;
		padding-right: 7%;
	}

	.header-sub-section-large-text {
		font: normal normal bold 60px/49px Josefin Sans;
		margin-top: 5%;
	}

	.special-offers-content div {
		margin-bottom: 3%;
	}

	.header-sub-section-small-text {
		text-align: left;
		font: normal normal normal 19px/26px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
		opacity: 1;
	}

	.top-product-title-link-wrapper.landing {
		justify-content: space-between;
		justify-content: space-between;
		margin-top: 15%;
		margin-bottom: 2%;
	}

	.top-product-title-link-wrapper.landing.services {
		width: 100%;
		padding-left: 2%;
		padding-right: 2%;
		box-sizing: border-box;
	}

	.top-product-section {
		width: 100%;
		align-items: center;
	}

	.top-services-section-wrapper {
		width: 100%;
	}

	.top-service-item-list {
		flex-direction: column;
		align-items: center;
		margin-bottom: 15%;
	}

	.top-service-item {
		width: 65%;
		margin-top: 15%;
	}

	/* .top-service-item .top-service-item-background-image {
		transform: scale(1.4);
	} */

	.top-service-item .top-service-item-content .top-service-item-text,
	.top-service-item:hover .top-service-item-content .top-service-item-link {
		opacity: 1;
	}

	.top-service-item-link {
		opacity: 1;
	}

	.renewable-energy-wrapper {
		width: 100%;
	}

	.renewable-energy-content {
		flex-direction: column;
	}
	.renewable-energy-section-image {
		width: 100%;
	}

	.renewable-packages-container {
		width: 100%;
	}

	.renewable-energy-section {
		margin-top: 10%;
	}

	.renewable-energy-title-wrapper {
		box-sizing: border-box;
		margin-left: 0;
		padding-left: 5%;
	}

	.renewable-packages-title {
		font: normal normal bold 30px/32px Josefin Sans;
		letter-spacing: 0px;
		color: #062a4e;
		margin-bottom: 5%;
	}

	.renewable-packages-text {
		font: normal normal normal 14px/18px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
	}

	.top-product-item-price-range.blog {
		opacity: 0;
		font: normal normal normal 14px/18px Josefin Sans;
	}

	.first-section-left {
	}

	.first-section-left h1 {
		font: normal normal bold 28px/34px Josefin Sans;
		letter-spacing: 0px;
		color: #fc2a00;
	}
}

/* Large screens ----------- */
@media only screen and (min-width: 1224px) {
	/* Styles */
	.special-offers-content h2 {
		font: 22px/25px Josefin Sans;
		/* font-weight: 600; */
	}

	.des-details {
		font: normal normal normal 14px/23px Josefin Sans;
	}

	.product-details-price {
		font: normal normal normal 20px/39px Tahoma;
	}

	.product-details-name {
		font: normal normal 600 20px/32px Josefin Sans;
	}

	.product-details-summary {
		font: normal normal normal 14px/23px Josefin Sans;
	}

	.header-sub-section-small-text {
		font: normal normal normal 20px/20px Josefin Sans;
	}

	.jumbotron-section-content-text {
		font: normal normal bold 53px/64px Josefin Sans;
		top: 10%;
	}

	.special-offers-content div {
		font: 46px Josefin Sans;
		font-weight: 600;
	}

	.special-offers-content a {
		width: 40%;
		height: 60px;
		font: Bold 14px/39px Josefin Sans;
		margin-top: 5%;
	}

	.top-product-title {
		font: 24px/50px Josefin Sans;
		font-weight: 600;
	}

	.renewable-packages-title {
		text-align: left;
		font: Bold 30px/50px Josefin Sans;
		letter-spacing: 0px;
	}

	.renewable-energy-title-wrapper {
		width: 100%;
		font: 24px/49px Josefin Sans;
		font-weight: 600;
		letter-spacing: 0px;
		color: #062a4e;
		margin-left: 3%;
		display: flex;
		justify-content: flex-start;
	}

	.special-offers-content p {
		font: 17px Josefin Sans;
		font-weight: 600;
	}

	.header-sub-section-image {
		top: -40%;
		width: 36%;
	}

	.top-product-item-price-range.blog ~ .top-product-item-button {
		font: Bold 14px/39px Josefin Sans;
	}

	.top-product-item-name {
		font: 18px Josefin Sans;
		font-weight: 600;
	}

	.products-filter-item {
		font: Bold 14px/25px Josefin Sans;
	}

	.top-product-item-price-range {
		font: 16px Josefin Sans;
	}

	.top-service-item-title {
		font: 18px/39px Josefin Sans;
		font-weight: 600;
	}

	.top-service-item-text {
		font: 13px Josefin Sans;
	}

	.renewable-packages-text {
		font: 16px/18px Josefin Sans;
	}

	.footer-link-list {
		font: 12px/20px Josefin Sans;
	}

	.services-content-left-header {
		font: 25px/42px Josefin Sans;
		font-weight: 600;
	}

	.services-content-left-text {
		font: 15px/18px Josefin Sans;
	}

	.top-product-link {
		font: 14px/39px Josefin Sans;
	}

	.special-offers-content {
		width: 80%;
	}

	.react-select__placeholder {
		font: Bold 12px Josefin Sans;
	}

	.services-content-left-button {
		height: 3.5rem;
		font: Bold 14px/25px Josefin Sans;
	}

	.top-product-items-list {
		min-height: 21rem;
	}
}

@media only screen and (min-width: 1824px) {
	/* Styles */
	.special-offers-content h2 {
		font: 26px/25px Josefin Sans;
		/* font-weight: 600; */
	}

	.des-details {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.product-details-price {
		font: normal normal normal 24px/39px Tahoma;
	}

	.product-details-name {
		font: normal normal 600 24px/32px Josefin Sans;
	}

	.product-details-summary {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.jumbotron-section-content-text {
		font: normal normal bold 66px/80px Josefin Sans;
		top: 15%;
	}

	.header-sub-section-small-text {
		font: normal normal normal 24px/30px Josefin Sans;
	}

	.special-offers-content div {
		font: 60px/80px Josefin Sans;
		font-weight: 600;
	}

	.special-offers-content a {
		width: 200px;
		height: 75px;
		font: Bold 18px/39px Josefin Sans;
	}

	.special-offers-content {
		width: 80%;
	}

	.products-filter-item {
		font: bold 18px/25px Josefin Sans;
	}

	.top-product-title {
		font: 30px/50px Josefin Sans;
		font-weight: 600;
	}

	.renewable-packages-title {
		text-align: left;
		font: Bold 38px/59px Josefin Sans;
		letter-spacing: 0px;
	}

	.renewable-energy-title-wrapper {
		width: 100%;
		font: 30px/49px Josefin Sans;
		font-weight: 600;
		letter-spacing: 0px;
		color: #062a4e;
		margin-left: 3%;
		display: flex;
		justify-content: flex-start;
	}

	.top-product-item-price-range.blog ~ .top-product-item-button {
		font: Bold 16px/39px Josefin Sans;
	}

	.special-offers-content p {
		font: 26px/25px Josefin Sans;
		font-weight: 600;
	}

	.top-product-item-name {
		font: 22px Josefin Sans;
		font-weight: 600;
	}

	.top-product-item-price-range {
		font: 20px Josefin Sans;
	}

	.top-service-item-title {
		font: 24px/39px Josefin Sans;
		font-weight: 600;
	}

	.top-product-link {
		font: 18px/49px Josefin Sans;
	}

	.top-service-item-text {
		font: 18px Josefin Sans;
	}

	.renewable-packages-text {
		font: 18px/18px Josefin Sans;
	}

	.footer-link-list {
		font: 12px/20px Josefin Sans;
	}

	.services-content-left-header {
		font: 30px/49px Josefin Sans;
		font-weight: 600;
	}

	.services-content-left-text {
		font: 18px/23px Josefin Sans;
	}

	.services-content-left-button {
		height: 4rem;
		font: Bold 18px/25px Josefin Sans;
	}
}

.services-section-container.company {
	background: #f7f7f7 0% 0% no-repeat padding-box;
}

.company-page-first-section {
	display: flex;
	justify-content: flex-end;
}

.first-section-left {
	position: absolute;
	left: 0;
	width: 53%;
	height: 40vh;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	/* padding: 5%; */
	padding-left: 10%;
	padding-right: 10%;
	padding-bottom: 4%;
	padding-top: 4%;
	background-color: white;
	box-sizing: border-box;
}

.first-section-left h1 {
	font: Bold 70px/80px Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	margin-top: 0;
	margin-bottom: 0;
}

.first-section-left p {
	font: 14px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.first-section-right {
	width: 50%;
	height: 50vh;
	/* display: flex; */
	/* background-color: white; */
	display: flex;
	align-items: flex-end;
	background: rgba(249, 196, 186, 0.3) 0% 0% no-repeat padding-box;
	border-bottom: solid #1fc6cd;
}

.first-section-right img {
	width: 100%;
	margin-top: 20%;
}

.company-page-second-section {
	display: flex;
	justify-content: flex-end;
	position: relative;
}

.second-section-left {
	width: 50%;
	position: absolute;
	left: 0;
	top: -3rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.second-section-left-text-group {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin-bottom: 1%;
	align-items: center;
}

.second-section-left-text-number {
	font: Bold 70px/13px Tahoma;
	letter-spacing: 0px;
	color: #1fc6cd;
	background: #eaf5f7 0% 0% no-repeat padding-box;
	border-radius: 11px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 6rem;
	width: 10rem;
	margin-right: 5%;
}

.second-section-left-text-small {
	font: 14px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.second-section-right {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.second-section-right > h3 {
	font: 30px/49px Josefin Sans;
	letter-spacing: 0px;
	font-weight: 600;
	color: #062a4e;
	margin-top: 15%;
}

.second-section-oem-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	background: #eaf5f7 0% 0% no-repeat padding-box;
	padding: 5%;
	box-sizing: border-box;
}

.oem-item-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5%;
}

.oem-image-wrapper {
	width: 20%;
}

.oem-image-wrapper img {
	width: 80%;
	mix-blend-mode: darken;
}
/* 
.company-page-third-section {
} */

.company-page-third-section-header-wrapper {
	padding-left: 5%;
}

.company-page-third-section-header-wrapper.clients {
	padding-left: 0%;
}

.company-page-third-section-header-wrapper h1 {
	font: Bold 70px Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	margin-top: 0px;
	margin-bottom: 0px;
}

.company-page-third-section-header-wrapper h3 {
	text-align: left;
	font: 25px Josefin Sans;
	letter-spacing: 0px;
	color: #1fc6cd;
	margin-top: 0.5rem;
}

.company-page-third-section-body {
	display: flex;
	width: 100%;
}

.company-page-third-section-left {
	width: 45%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 4%;
	padding-bottom: 4%;
	background: #f9c4ba36 0% 0% no-repeat padding-box;
	z-index: 1;
}

.company-page-third-section-left img {
	width: 100%;
	padding-left: 20%;
	position: relative;
}

.company-page-third-section-right {
	width: 55%;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	flex-direction: column;
	/* padding: 5%; */
	box-sizing: border-box;
	z-index: 0;
}

.company-third-section-right-header {
	text-align: center;
	font: 25px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	font-weight: 600;
	width: 65%;
	margin-top: 10%;
	margin-bottom: 5%;
}

.company-third-section-side-text-wrapper {
	display: flex;
	justify-content: center;
	text-align: center;
	width: 100%;
	position: relative;
	background-color: white;
	flex-direction: column;
	align-items: center;
	padding-top: 10%;
	padding-bottom: 10%;
	margin-bottom: 2%;
}

.company-third-section-background {
	position: absolute;
	width: 30%;
}

.company-third-section-background img {
	width: 100%;
}

.company-third-section-qoute-text {
	display: flex;
	justify-content: center;
	text-align: center;
	text-align: center;
	font: 12px/19px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	width: 50%;
}

.company-third-section-qoute-text.name {
	font-size: 20px;
	margin-top: 5%;
	margin-bottom: 2%;
}

.company-third-section-qoute-text.italic {
	text-align: center;
	font: Italic 12px/15px Josefin Sans;
}

.company-page-fourth-section {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10%;
}

.company-page-fourth-section-sub {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 20px #0000000d;
	width: 50%;
	padding: 4%;
	box-sizing: border-box;
}

.company-page-fourth-section-sub.left-top {
	position: absolute;
	left: 0;
	top: -3rem;
}

.company-page-fourth-section-sub.right-bottom {
	position: absolute;
	bottom: -3rem;
	right: 0;
}

.company-page-fourth-section-sub-header {
	font: Bold 12px/30px Josefin Sans;
	letter-spacing: 0.18px;
	color: #062a4e;
}

.company-page-fourth-section-sub-text {
	text-align: left;
	font: 12px/15px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.company-page-fourth-section-image {
	width: 55%;
}

.company-page-fourth-section-image img {
	width: 100%;
}

.company-page-fifth-section {
	margin-top: 13%;
}

.company-page-third-section-body {
	display: flex;
	margin-top: 5%;
}

.company-page-third-section-body-left {
	width: 45%;
	z-index: 2;
	position: relative;
	right: -5%;
	display: flex;
	/* align-items: center; */
	flex-direction: column;
}

.company-page-third-section-body-left__text {
	text-align: left;
	font: 25px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	font-weight: 600;
	width: 65%;
	margin-bottom: 5%;
}

.company-page-third-section-body-left__image {
	width: 100%;
	background: #caeef44a 0% 0% no-repeat padding-box;
}

.company-page-third-section-body-left__image .img {
	width: 100%;
	/* position: absolute; */
	/* left: 5%; */
	padding-top: 10%;
	padding-bottom: 10%;
	padding-right: 12%;
	padding-left: 12%;
	/* background: #caeef44a 0% 0% no-repeat padding-box; */
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.img img {
	box-sizing: border-box;
	mix-blend-mode: darken;
	width: 19%;
	min-width: 3rem;
	/* height: 3rem; */
	margin-right: 2%;
}

.img-top {
	margin-bottom: 10%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.img-bottom {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.company-page-third-section-body.projects {
	flex-direction: column;
}

.project-list-container {
	display: flex;
	flex-wrap: wrap;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 20px #0000000d;
	padding: 5%;
	align-items: flex-start;
	justify-content: space-between;
	padding-left: 7%;
	padding-right: 7%;
}

.project-list-item {
	width: 45%;
	/* margin-left: 5%; */
	margin-bottom: 2%;
	margin-top: 2%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: normal;
}

.project-list-item-top {
	display: flex;
	justify-content: space-between;
	/* align-items: center; */
	margin-bottom: 5%;
}

.project-list-item-top-text-header {
	text-align: left;
	font: 15px/19px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
	font-weight: 200;
	margin-bottom: 5%;
}

.project-list-item-top-text-company {
	text-align: left;
	font: Bold 15px/30px Josefin Sans;
	letter-spacing: 0.18px;
	color: #062a4e;
	opacity: 1;
}

.project-list-item-top-image-wrapper {
	width: 20%;
}

.project-list-item-top-image-wrapper img {
	width: 100%;
}

.project-list-item-bottom-header {
	text-align: left;
	font: Bold 15px/30px Josefin Sans;
	letter-spacing: 0.18px;
	color: #062a4e;
	opacity: 1;
}

.project-list-item-bottom-list {
	text-align: left;
	font: 13px/23px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.company-page-third-section.careers {
	margin-top: 10%;
}

.company-page-third-section-body.careers {
	justify-content: space-between;
}

.career-card {
	display: flex;
	flex-direction: column;
	width: 30%;
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
}

.career-card-header {
	text-align: left;
	font: Bold 20px/35px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	/* background: #f9c4ba36 0% 0% no-repeat padding-box; */
	background: #f9c4ba2e 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	padding-left: 10%;
	padding-right: 10%;
	padding-top: 5%;
	padding-bottom: 5%;
}

.career-card-body {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
}

.career-card-body-text {
	text-align: left;
	letter-spacing: 0px;
	color: #062a4e;
	opacity: 1;
	margin-top: 10%;
	margin-bottom: 5%;
	margin-left: 10%;
	margin-right: 10%;
	font-family: Josefin Sans;
}

.career-card-body-button {
	text-decoration: none;
	color: #fc2a00;
	border: 1px solid #fc2a00;
	text-align: center;
	font: Bold 18px/25px Josefin Sans;
	background-color: white;
	letter-spacing: 0px;
	color: #fc2a00;
	padding-left: 10%;
	padding-right: 10%;
	padding-top: 5%;
	padding-bottom: 5%;
	margin-bottom: 10%;
	margin-top: 5%;
	cursor: pointer;
	align-self: flex-start;
	margin-left: 10%;
}

/* .career-card-body-button a {
	
} */

.career-card-body-button:focus {
	border: 1px solid #fc2a00;
	outline: none;
}

.project-list-item-bottom {
	/* height: 7rem; */
}

.simple-trans-main {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.simple-trans-main > div {
	will-change: transform, opacity, position;
	cursor: pointer;
	box-sizing: border-box;
	height: 70vh;
}

.slider-wrapper {
	position: relative;
	display: flex;
	align-items: center;
}

.left-arrow {
	position: absolute;
	left: -9%;
	cursor: pointer;
	width: 6%;
	transform: rotate(180deg);
	transition: all 0.3s ease-in-out;
}

.left-arrow:hover {
	/* background-color: #1fc6cd; */
}

.left-arrow img {
	width: 100%;
	transition: all 0.01s ease-in-out;
}

.right-arrow img {
	width: 100%;
	transition: all 0.01s ease-in-out;
}
.right-arrow:hover img {
	/* background-color: #1fc6cd;
	mix-blend-mode: darken; */
	-webkit-filter: invert(5%) sepia(26%) saturate(5807%) hue-rotate(173deg)
		brightness(108%) contrast(92%);
	        filter: invert(5%) sepia(26%) saturate(5807%) hue-rotate(173deg)
		brightness(108%) contrast(92%);
}

.left-arrow:hover img {
	/* background-color: #1fc6cd; */
	/* background-blend-mode: multiply; */
	-webkit-filter: invert(5%) sepia(26%) saturate(5807%) hue-rotate(173deg)
		brightness(108%) contrast(92%);
	        filter: invert(5%) sepia(26%) saturate(5807%) hue-rotate(173deg)
		brightness(108%) contrast(92%);
}

.right-arrow {
	position: absolute;
	right: -9%;
	cursor: pointer;
	width: 6%;
	transition: all 0.3s ease-in-out;
}

.right-arrow:hover {
}

.invisible {
	visibility: hidden;
}

.oem-logos {
	width: 100%;
}

.img .client-logos-img {
	width: 100%;
}

@media only screen and (max-width: 500px) {
	.company-page-first-section {
		height: auto;
		flex-direction: column-reverse;
		align-items: center;
	}
	.first-section-left h1 {
		font: normal normal bold 32px/34px Josefin Sans;
		letter-spacing: 0px;
		color: #fc2a00;
		margin-bottom: 7%;
	}

	.second-section-left {
		position: relative;
		top: 0;
		/* justify-content: center; */
		align-self: center;
		width: 90%;
	}

	.second-section-left-text-small {
		width: 50%;
	}

	.company-page-second-section {
		margin-top: 10%;
		flex-direction: column;
	}

	.first-section-left {
		width: 100%;
	}

	.first-section-left p {
		font: 18px/18px Josefin Sans;
	}

	.first-section-right {
		width: 60%;
		justify-content: center;
		align-items: flex-end;
		height: auto;
		bottom: 7rem;
		z-index: 2;
		margin-top: 35vh;
	}

	.first-section-right img {
		width: 100%;
		margin-top: 10%;
	}

	.second-section-right {
		align-self: center;
		width: 90%;
		margin-bottom: 10%;
	}

	.second-section-right > h3 {
		font: normal normal 600 22px/49px Josefin Sans;
	}

	.company-page-third-section-header-wrapper {
		width: 100%;
		box-sizing: border-box;
	}

	.company-page-third-section-header-wrapper h1 {
		font: normal normal bold 28px/49px Josefin Sans;
		letter-spacing: 0px;
		color: #fc2a00;
	}

	.company-page-third-section-header-wrapper h3 {
		font: normal normal 300 18px Josefin Sans;
		letter-spacing: 0px;
		color: #1fc6cd;
	}

	.company-page-third-section-body {
		flex-direction: column;
		align-items: center;
	}

	.company-page-third-section-left {
		width: 75%;
		/* margin-left: 5%; */
	}

	.company-page-third-section-right {
		width: 100%;
		margin-bottom: 15%;
	}

	.company-third-section-right-header {
		font: normal normal 600 22px/30px Josefin Sans;
		letter-spacing: 0px;
		color: #062a4e;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		margin-top: 15%;
		margin-bottom: 7%;
	}

	.company-third-section-background {
		width: 45%;
	}

	.company-third-section-qoute-text {
		font: normal normal normal 12px/16px Josefin Sans;
		letter-spacing: 0px;
		color: #062a4e;
		width: 65%;
	}

	.company-page-fourth-section-sub.left-top {
		width: 70%;
		padding-bottom: 7%;
		padding-top: 7%;
		padding-left: 5%;
		padding-right: 5%;
		top: -5rem;
	}

	.company-page-fourth-section-sub-header {
		text-align: left;
		font: normal normal bold 18px Josefin Sans;
		letter-spacing: 0.18px;
		color: #062a4e;
		margin-bottom: 5%;
	}

	.company-page-fourth-section-sub-text {
		text-align: left;
		font: normal normal normal 12px/20px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
	}

	.company-page-fourth-section-image {
		width: 90%;
	}

	.company-page-fourth-section-sub.right-bottom {
		bottom: -5rem;
		width: 70%;
		padding-bottom: 7%;
		padding-top: 7%;
		padding-left: 5%;
		padding-right: 5%;
	}

	.company-page-fourth-section {
		margin-top: 25%;
		margin-bottom: 25%;
	}

	.company-page-fourth-section-sub.right-bottom div {
		text-align: right;
	}

	.company-page-third-section-header-wrapper.clients {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		padding-left: 5%;
		box-sizing: border-box;
		font: normal normal bold 28px/49px Josefin Sans;
		letter-spacing: 0px;
		color: #fc2a00;
	}

	.company-page-third-section-body-left {
		width: 90%;
		right: 0;
		display: flex;
		align-items: center;
		margin-top: 5%;
	}

	.company-page-third-section-body-left__text {
		margin-bottom: 10%;
		white-space: nowrap;
	}

	.project-list-container {
		flex-direction: column;
	}

	.project-list-item {
		width: 100%;
		margin-bottom: 15%;
	}

	.career-card {
		width: 90%;
	}

	.company-page-third-section-body.careers {
		margin-bottom: 10%;
	}

	.career-card-body-button {
		white-space: nowrap;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.career-card-header {
		font: Bold 20px/28px Josefin Sans;
	}

	.right-arrow,
	.left-arrow {
		display: none;
	}

	.simple-trans-main > div {
		height: auto;
	}
}

@media only screen and (min-width: 1224px) {
	.first-section-left p {
		font: 17px/18px Josefin Sans;
	}

	.second-section-left-text-small {
		font: 17px/18px Josefin Sans;
	}

	.company-third-section-right-header {
		font: 25px Josefin Sans;
	}

	.company-third-section-qoute-text {
		font: 14px/19px Josefin Sans;
	}

	.company-third-section-qoute-text.name {
		font-size: 23px;
	}

	.company-third-section-qoute-text.italic {
		font: Italic 14px/15px Josefin Sans;
	}

	.company-page-fourth-section-sub-header {
		font: Bold 14px/30px Josefin Sans;
	}

	.company-page-fourth-section-sub-text {
		font: 14px/15px Josefin Sans;
	}

	.project-list-item-bottom-list {
		font: 15px/23px Josefin Sans;
	}

	.project-list-item-bottom-header {
		font: Bold 17px/30px Josefin Sans;
	}

	.project-list-item-top-text-company {
		font: Bold 17px/30px Josefin Sans;
	}

	.project-list-item-top-text-header {
		font: 17px/19px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
		font-weight: 200;
	}
}

@media only screen and (min-width: 1824px) {
	/* Styles */

	.first-section-left h1 {
		font: normal normal bold 90px/109px Josefin Sans;
	}

	.first-section-left p {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.second-section-left-text-small {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.second-section-right > h3 {
		font: normal normal 600 40px/49px Josefin Sans;
	}

	.company-page-third-section-header-wrapper h1 {
		font: normal normal bold 90px/49px Josefin Sans;
	}

	.company-page-third-section-header-wrapper h3 {
		font: normal normal normal 35px Josefin Sans;
	}

	.company-third-section-right-header {
		font: normal normal 600 40px/86px Josefin Sans;
	}

	.company-third-section-qoute-text {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.company-page-fourth-section-sub-header {
		font: normal normal bold 18px/40px Josefin Sans;
	}

	.company-page-fourth-section-sub-text {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.project-list-item-bottom-list {
		font: normal normal normal 18px/23px Josefin Sans;
	}
}

.contact-us-head-office-left {
	width: 45%;
	position: relative;
}

.contact-us-location-title {
	font: Bold 70px Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	margin-top: 5%;
	margin-bottom: 0px;
	padding-left: 0;
}

.contact-us-location-left-body {
	display: flex;
	flex-direction: column;
	margin-top: 5%;
	position: relative;
	left: 5%;
}

.contact-us-location-state {
	text-align: left;
	font: 25px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	font-weight: 600;
	width: 65%;
	/* margin-bottom: 5%; */
}

.contact-us-location-state.sub-right {
	margin-left: 10%;
}

.contact-us-location-content-wrapper {
	display: flex;
	/* background: #f9c4ba1a 0% 0% no-repeat padding-box; */
}

.contact-us-location-content-image-wrapper {
	width: 17%;
	margin-right: 5%;
	margin-top: 5%;
}

.contact-us-location-content-image-wrapper svg {
	width: 100%;
}

.contact-us-location-content {
	margin-top: 2%;
	background: #f9c4ba1a 0% 0% no-repeat padding-box;
	padding: 5%;
	padding-right: 15%;
	padding-left: 10%;
	z-index: 1;
}

.contact-us-location-content-address {
	text-align: left;
	font: 14px/20px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
}

.contact-us-location-content-phone-wrapper {
	display: flex;
	margin-top: 7%;
}

.contact-us-location-phone-image {
	margin-right: 5%;
	width: 10%;
}

.contact-us-location-phone-image svg {
	width: 100%;
}

.contact-us-location-phone-numbers {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	text-decoration: none;
}

.location-phone-number {
	width: 50%;
	/* margin-bottom: 5%; */
	text-align: left;
	font: 14px/20px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	margin-top: 0;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	text-decoration: none;
}

.location-phone-number a {
	text-decoration: none;
	color: #062a4e;
}

.contact-us-head-office-right {
	width: 55%;
	position: relative;
	display: flex;
	justify-content: center;
}

.contact-us-head-office-right-image {
	width: 80%;
	position: relative;
	top: 0;
	z-index: 1;
	margin-bottom: 10%;
}

.contact-us-head-office-right-image img {
	width: 100%;
}

.contact-us-head-office-right-background {
	position: absolute;
	height: 55%;
	background: #caeef4 0% 0% no-repeat padding-box;
	opacity: 0.2;
	width: 100%;
	bottom: 0;
	z-index: 0;
}

.services-content-item.contact {
	align-items: flex-start;
}

.services-content-item.contact-sub {
	margin-top: 0%;
	align-items: flex-start;
}

.contact-us-location-left-body.sub {
	width: 45%;
	left: 2%;
	margin-top: 1%;
	/* left: 0; */
}

.contact-us-location-left-body.sub-right {
	margin-top: 10%;
	width: 55%;
	box-sizing: border-box;
	position: relative;
	left: 0;
	display: flex;
	justify-content: flex-start;
}

.contact-us-location-content-image-wrapper.sub-right {
	margin-left: 5%;
	margin-right: 7%;
}

.contact-us-location-content.sub-right {
	background: none;
	margin-top: 0%;
	padding-left: 0;
}

.contact-us-location-content-wrapper.sub-right {
	display: flex;
	padding-left: 5%;
	box-sizing: border-box;
	margin-top: 1%;
	width: 90%;
	background: #caeef43d 0% 0% no-repeat padding-box;
}

.contact-section-left {
	width: 45%;
}

.contact-section-left-header {
	font: Bold 70px Josefin Sans;
	letter-spacing: 0px;
	color: #1fc6cd;
	/* margin-top: 5%; */
	margin-bottom: 0px;
	width: 100%;
}

.contact-section-left-form {
	display: flex;
	flex-direction: column;
	margin-top: 5%;
	position: relative;
	left: 4%;
	margin-top: 1%;
	/* padding-top: 10%; */
	align-items: flex-start;
}

.contact-section-left-coloured {
	width: 100%;
	padding-top: 10%;
	background: #caeef43d 0% 0% no-repeat padding-box;
	padding-left: 5%;
	box-sizing: border-box;
	padding-right: 10%;
	padding-bottom: 2%;
	/* border-radius: 1rem; */
}

.contact-section-left-double-input-item {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5%;
}

.contact-section-left-double-input-item > input,
.contact-section-left-double-input-item > div {
	width: 46%;
	height: 3rem;
	background: #ffffff 0% 0% no-repeat;
	border: none;
	padding-left: 5%;
	box-sizing: border-box;
	border-radius: 0.5rem;
}

.contact-section-left-double-input-item > input::-webkit-input-placeholder {
	font: Bold 14px josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.contact-section-left-double-input-item > input:-ms-input-placeholder {
	font: Bold 14px josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.contact-section-left-double-input-item > input::-ms-input-placeholder {
	font: Bold 14px josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.contact-section-left-double-input-item > input::placeholder {
	font: Bold 14px josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.contact-section-left-single-input-item {
	padding-left: 5%;
	width: 100%;
	border: none;
	height: 3rem;
	margin-top: 5%;
	margin-bottom: 5%;
	box-sizing: border-box;
	border-radius: 0.5rem;
}

.contact-section-left-single-input-item.select {
	width: 85%;
	height: 3rem;
	margin-left: 5%;
	padding-left: 0;
	display: flex;
}

.contact-section-left-single-input-item.select > div {
	border: none;
	padding-left: 3%;
	width: 100%;
}

.contact-section-left-single-input-item.message {
	padding: 5%;
	height: 8rem;
	width: 85%;
	margin-left: 5%;
	margin-top: 1%;
}

.contact-section-left-single-input-item::-webkit-input-placeholder {
	font: Bold 14px Josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.contact-section-left-single-input-item:-ms-input-placeholder {
	font: Bold 14px Josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.contact-section-left-single-input-item::-ms-input-placeholder {
	font: Bold 14px Josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.contact-section-left-single-input-item::placeholder {
	font: Bold 14px Josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.mailto-button {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mailto-button a {
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: 3rem; */
	background: #fc2a00 0% 0% no-repeat padding-box;
	text-decoration: none;
	color: white;
	text-align: center;
	font: Bold 14px/49px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
}

.contact-section-right {
	width: 55%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-section-right a {
	display: flex;
	margin-bottom: 5%;
	margin-top: 10%;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.contact-section-right-mailto-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-section-right-mailto-content-image {
	width: 20%;
	margin-bottom: 5%;
}

.contact-section-right-mailto-content-image img {
	width: 100%;
}

.contact-section-right-mailto-content-email {
	font: 28px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	font-weight: 200;
}

.contact-section-right-image {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.contact-section-right-image-background {
	position: absolute;
	height: 55%;
	background: #f9c4ba 0% 0% no-repeat padding-box;
	opacity: 0.18;
	width: 100%;
	top: 0;
	z-index: 0;
}

.contact-section-right-image-wrapper {
	width: 80%;
	position: relative;
	top: 0;
	z-index: 1;
	margin-top: 11%;
	display: flex;
	justify-content: center;
}

.contact-section-right-image-wrapper img {
	width: 80%;
}

#location-select {
	padding-left: 0;
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

#location-select > div {
	width: 100%;
	/* height: 100%; */
	padding-left: 5%;
	border: none;
	display: flex;
}

.bottom-label {
	margin-left: 9%;
	margin-top: 3%;
	text-align: left;
	font: 14px/20px Josefin Sans;
	letter-spacing: 0px;
	color: #707070;
}

@media only screen and (max-width: 500px) {
	.services-content-item.contact {
		flex-direction: column;
		align-items: center;
		margin-bottom: 5%;
	}

	.contact-us-head-office-left {
		width: 100%;
	}

	.contact-us-head-office-right {
		width: 90%;
	}

	.contact-us-location-title {
		padding-left: 5%;
		font: Bold 40px Josefin Sans;
	}

	.contact-us-location-state {
		font: 19px Josefin Sans;
		font-weight: 600;
		margin-bottom: 5%;
	}

	.services-content-item.contact-sub {
		flex-direction: column;
		align-items: center;
		margin-bottom: 5%;
	}

	.contact-us-head-office-right-background {
		height: 100%;
	}

	.contact-us-location-left-body {
		width: 95%;
		box-sizing: border-box;
	}

	.contact-us-location-left-body.sub-right {
		width: 90%;
	}

	.contact-us-location-left-body.sub {
		width: 90%;
	}

	.contact-us-location-state.sub-right {
		margin-left: 0%;
	}

	.contact-section-left {
		width: 100%;
	}

	.contact-us-location-content-wrapper {
		width: 100%;
	}

	.contact-section-left-form {
		left: 0;
		align-items: center;
	}

	.contact-section-left-header {
		font: Bold 40px Josefin Sans;
		margin-bottom: 5%;
		padding-left: 5%;
		box-sizing: border-box;
	}

	.contact-section-left-coloured {
		padding-left: 5%;
		padding-right: 5%;
	}

	.bottom-label {
		align-self: start;
	}

	.contact-section-left-single-input-item.select {
		width: 90%;
		margin-left: 0%;
	}

	.contact-section-left-single-input-item.message {
		margin-left: 0;
		width: 90%;
	}

	.contact-section-right {
		width: 90%;
	}

	.contact-section-left-single-input-item::-webkit-input-placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}

	.contact-section-left-single-input-item:-ms-input-placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}

	.contact-section-left-single-input-item::-ms-input-placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}

	.contact-section-left-single-input-item::placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}

	.contact-section-left-double-input-item > input::-webkit-input-placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}

	.contact-section-left-double-input-item > input:-ms-input-placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}

	.contact-section-left-double-input-item > input::-ms-input-placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}

	.contact-section-left-double-input-item > input::placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}
}

@media only screen and (min-width: 1224px) {
	.contact-us-location-content-address {
		font: 16px/20px Josefin Sans;
	}
}

@media only screen and (min-width: 1824px) {
	/* Styles */

	.contact-us-location-title {
		font: normal normal bold 90px/49px Josefin Sans;
	}

	.contact-us-location-state {
		font: normal normal 600 30px/49px Josefin Sans;
	}

	.contact-us-location-content-address {
		font: normal normal normal 18px/30px Josefin Sans;
	}

	.location-phone-number {
		font: normal normal normal 16px/34px Josefin Sans;
	}

	.contact-section-right-mailto-content-email {
		font: normal normal 300 38px/116px Josefin Sans;
	}
}

.nav-container {
	background: #062a4e 0% 0% no-repeat padding-box;
	display: flex;
	justify-content: center;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 4;
}

.nav-wrapper {
	height: 70px;
	width: 90%;
	max-width: 1440px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* UI Properties */
}

.nav-links-brand-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 35%;
	min-width: 25rem;
}

.nav-brand-wrapper {
	width: 10%;
}

.nav-brand-wrapper svg {
	width: 100%;
}

.nav-links-wrapper {
	width: 80%;
	display: flex;
	justify-content: space-between;
}

.nav-bar-item {
	text-decoration: none;
	font: 14px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
	transition: all ease-in-out 0.3s;
}

.nav-bar-item:hover {
	color: #caeef4;
}

.nav-bar-item.active {
	color: #1fc6cd;
}

.nav-logo-wrapper {
	width: 10%;
	margin-left: 3%;
}

.nav-logo-wrapper svg {
	width: 100%;
}

.nav-logo-wrapper.mobile {
	display: none !important;
}

@media only screen and (max-width: 500px) {
	.nav-links-wrapper {
		display: none;
	}

	.nav-brand-wrapper {
		width: 100%;
	}

	.nav-links-brand-wrapper {
		min-width: 10%;
		display: flex;
		justify-content: flex-start;
		width: 15%;
	}

	.nav-logo-wrapper {
		display: none;
	}

	.nav-logo-wrapper.mobile {
		display: flex !important;
	}
}

@media only screen and (min-width: 1224px) {
	/* Styles */
	.nav-bar-item {
		font: normal normal normal 14px/25px Josefin Sans;
	}
}

@media only screen and (min-width: 1824px) {
	/* Styles */
	.nav-bar-item {
		font: normal normal normal 16px/25px Josefin Sans;
	}
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		        animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

