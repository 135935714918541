.page-container {
	width: 100%;
	display: flex;
	justify-content: center;
	background: #f7f7f7 0% 0% no-repeat padding-box;
	flex-direction: column;
	align-items: center;
}

.jumbotron-section {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
	/* max-width: 1440px; */
	z-index: 1;
}

.jumbotron-section-content {
	width: 70%;
	position: relative;
}

.jumbotron-section-content img {
	width: 100%;
}

.header-sub-section {
	width: 90%;
	display: flex;
	justify-content: flex-start;
	position: relative;
	/* max-width: 1440px; */
}

.header-sub-section-content {
	background: #f5feff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 46px #00000012;
	width: 48%;
	padding: 5%;
	margin-left: 7%;
	box-sizing: border-box;
	margin-top: -0.3%;
}

.header-sub-section-large-text {
	font: Bold 4rem Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	margin-bottom: 3%;
}

.header-sub-section-small-text {
	font: 1.3rem Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.header-sub-section-image {
	position: absolute;
	top: -55%;
	right: 5%;
	width: 32%;
	z-index: 3;
}

.header-sub-section-image img {
	width: 100%;
}

.jumbotron-section-content-text {
	font: Bold 3.5rem Josefin Sans;
	letter-spacing: 0px;
	color: #1fc6cd;
	position: absolute;
	left: 11%;
	top: 15%;
}

.jumbotron-section-content-text span {
	color: #fc2a00;
}

.special-offers-wrapper {
	position: relative;
	/* top: 2%; */
	overflow: hidden;
	right: 5%;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	/* height: 45%; */
	background: url(../../assets/Group9337.png) 30% 0% no-repeat padding-box;
	background-size: cover;
}

.special-offers-content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.special-offers-content h2 {
	font: 1.3rem Josefin Sans;
	/* font-weight: 300; */
	letter-spacing: 0px;
	color: #062a4e;
	text-align: center;
	margin-bottom: 10%;
	/* margin-top: 20%; */
}

.special-offers-content div {
	font: 2.5rem Josefin Sans;
	font-weight: 600;
	letter-spacing: -3.15px;
	color: #fff;
	text-align: center;
	margin-bottom: 5%;
}
.special-offers-content p {
	font: 0.81rem Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	text-align: center;
}
.special-offers-content a {
	display: flex;
	width: 40%;
	text-decoration: none;
	justify-content: center;
	align-items: center;
	background: #fc2a00 0% 0% no-repeat padding-box;
	font: Bold 12px/39px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
	margin-top: 10%;
	text-align: center;
	transition: all ease-in-out 0.3s;
	/* margin-left: 30%; */
	/* margin-bottom: 20%; */
}

.special-offers-content a:hover {
	background: #062a4e 0% 0% no-repeat padding-box;
	color: #caeef4;
}

.top-product-section {
	width: 78%;
	display: flex;
	flex-direction: column;
	margin-top: 5%;
	margin-bottom: 7%;
}

.top-product-title-link-wrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.top-product-title {
	font: 20px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #062a4e;
	margin-left: 3%;
}

.top-product-link {
	font: 12px/49px Josefin Sans;
	letter-spacing: 0px;
	text-decoration: none;
	color: #000000;
	transform: scale(1);
	margin-right: 3%;
	transition: all ease-in-out 0.3s;
	display: flex;
	justify-content: space-between;
	width: 12%;
	align-items: center;
}

.exit-group {
	display: flex;
}

.top-product-link:hover {
	transform: scale(1.03);
}

.top-product-link .exit-group .exit2 {
	margin-left: 0%;
	transition: all ease-in-out 0.3s;
}

.top-product-link:hover .exit-group .exit2 {
	margin-left: 15%;
}

.top-product-link .exit-group .exit2.blog {
	margin-left: 0%;
	transition: all ease-in-out 0.3s;
	position: relative;
	right: 35%;
	margin-bottom: 20%;
}

.top-product-link:hover .exit-group .exit2.blog {
	right: 32%;
	margin-bottom: 35%;
}

.top-product-items-list {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	background: #ffffff 0% 0% no-repeat padding-box;
	height: 46vh;
	min-height: 19rem;
}

.top-product-item-wrapper {
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0%;
	padding-left: 3%;
	padding-right: 3%;
	padding-bottom: 3%;
	box-sizing: border-box;
	margin-top: 4%;
	transform: scale(1);
	transition: all ease-in-out 0.3s;
}

.top-product-item-wrapper.blog {
	padding-bottom: 3%;
	text-decoration: none;
}

.top-product-item-wrapper .top-product-item-button {
	/* width: 32%; */
	display: flex;
	flex-direction: column;
	align-items: center;
}

.top-product-item-wrapper:hover {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 96px #00000019;
	/* height: 42vh; */
	/* min-height: 20rem; */
	transform: scale(1.03);
	/* justify-content: space-evenly; */
	height: fit-content;
}

.top-product-item-wrapper.product:hover {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 96px #00000019;
	height: auto;
	min-height: 19rem;
	transform: scale(1.03);
	justify-content: space-evenly;
}

.top-product-item-wrapper.product {
	margin-right: 3%;
}

.top-product-item-wrapper:hover .top-product-item-button {
	opacity: 1;
}

.top-product-item-wrapper img {
	width: 100%;
}

.top-product-item-name {
	font: 15px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #000000;
	text-align: center;
	margin-top: 5%;
	text-decoration: none;
	cursor: pointer;
}

.top-product-item-price-range {
	font: 10px Josefin Sans;
	letter-spacing: 0px;
	color: #767676;
	margin-top: 5%;
}

.top-product-item-wrapper .top-product-item-button {
	opacity: 0;
	transition: all ease-out 0.2s;
	margin-bottom: 2%;
}

.top-product-item-button {
	width: 40%;
	text-decoration: none;
	justify-content: center;
	align-items: center;
	background: #fc2a00 0% 0% no-repeat padding-box;
	font: Bold 12px/39px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
	margin-top: 5%;
	transition: all ease-in-out 0.3s;
}

.top-product-item-button:hover {
	background: #062a4e 0% 0% no-repeat padding-box;
	color: #caeef4;
}

.top-services-section {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 5%;
}

.top-services-section-wrapper {
	width: 78%;
	/* margin-bottom: 30%; */
}

.top-service-item-list {
	display: flex;
	justify-content: space-evenly;
	margin-top: 5%;
}

.top-service-item {
	width: 25%;
	position: relative;
	display: flex;
	/* height: 22%; */
	justify-content: center;
	align-items: center;
}

/* .top-service-item svg {
	width: 100%;
	position: absolute;
	top: 0;
	z-index: -1;
} */

.top-service-item-background-image {
	width: 100%;
	position: absolute;
	/* top: 0px; */
	display: flex;
	/* justify-content: center; */
	align-items: center;
	/* height: 100%; */
	z-index: 1;
	transform: scale(0);
	transition: all ease-in-out 0.3s;
}

.top-service-item:hover .top-service-item-background-image {
	transform: scale(1.4);
}

.top-service-item-background-image img {
	width: 100%;
	height: 100%;
}

.top-service-item-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* height: 100%; */
	width: 95%;
	z-index: 2;
}

.top-service-item-inner-svgs {
	position: relative;
	width: 100%;
	display: flex;
	/* justify-content: center; */
	align-items: flex-end;
	transition: all ease-in-out 0.3s;
}

.top-service-item-inner-background {
	width: 100%;
	/* margin-top: 40%; */
	/* height: 100%; */
	display: flex;
	justify-content: center;
	border-bottom: 3px solid #8dd8e0;
}

.top-service-item-inner-background svg {
	width: 100%;
	height: 100%;
	/* border-bottom: 1px solid blue; */
}

.top-service-item-inner-worker-svg {
	position: absolute;
	left: 0;
	width: 30%;
	margin-left: 15%;
	transition: all ease-in-out 0.3s;
}

.top-service-item-inner-worker-svg svg {
	width: 100%;
	height: 100%;
}

.top-service-item:hover .top-service-item-content .top-service-item-text,
.top-service-item:hover .top-service-item-content .top-service-item-link {
	opacity: 1;
}

.top-service-item-title {
	font: 18px/39px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #0d0d0d;
	margin-top: 5%;
}

.top-service-item-text {
	opacity: 0;
	margin-top: 5%;
	font: 11px Josefin Sans;
	letter-spacing: 0px;
	color: #0d0d0d;
	text-align: center;
	transition: all ease-in-out 0.3s;
}

.top-service-item-link {
	margin-top: 10%;
	opacity: 0;
	transition: all ease-in-out 0.3s;
	font: Bold 14px/39px Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	cursor: pointer;
	text-decoration: none;
}

.top-service-item:hover .top-service-item-content .top-service-item-inner-svgs {
	transform: scale(1.1);
}

.top-service-item:hover
	.top-service-item-content
	.top-service-item-inner-svgs
	.top-service-item-inner-worker-svg {
	margin-left: 10%;
}

.renewable-energy-section {
	width: 100%;
	display: flex;
	justify-content: center;
	/* margin-bottom: 5%; */
}

.renewable-energy-wrapper {
	width: 78%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
}

.renewable-energy-title-wrapper {
	width: 100%;
	font: 20px/49px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #062a4e;
	margin-left: 3%;
	display: flex;
	justify-content: flex-start;
}

.renewable-energy-content {
	display: flex;
	justify-content: space-between;
	margin-top: 2%;
}

.renewable-energy-section-image {
	width: 40%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.renewable-energy-section-image img {
	width: 95%;
	mix-blend-mode: darken;
	margin-top: 5%;
	margin-bottom: 5%;
}

.renewable-packages-container {
	width: 50%;
	background: #ffffff 0% 0% no-repeat padding-box;
	padding-top: 5%;
	padding-bottom: 5%;
	padding-left: 5%;
	padding-right: 5%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.top-product-item-price-range.blog {
	opacity: 0;
	margin-top: 5%;
	font: 10px Josefin Sans;
	letter-spacing: 0px;
	color: #0d0d0d;
	text-align: center;
	transition: all ease-in-out 0.3s;
}

.top-product-item-price-range.blog ~ .top-product-item-button {
	background: none;
	color: #1fc6cd;
}

.renewable-packages-title {
	text-align: left;
	font: Bold 25px/49px Josefin Sans;
	letter-spacing: 0px;
}

.renewable-packages-title span {
	color: #1fc6cd;
}

.renewable-packages-text {
	text-align: left;
	font: 12px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.renewable-packages {
	display: flex;
	justify-content: flex-start;
	font: Bold 20px/39px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
}

.renewable-packages ul {
	list-style: none;
	padding-inline-start: 0px;
}

.renewable-packages ul li::before {
	content: "\2022";
	color: #caeef4;
	font-weight: bold;
	display: inline-block;
	width: 1em;

	/* margin-left: -1em; */
}

.renewable-packages ul:last-child {
	padding-inline-start: 40px;
}

.renewable-packages-container .top-product-item-button {
	width: 30%;
	display: flex;
	text-decoration: none;
	justify-content: center;
	align-items: center;
	margin-top: 0;
}

.top-product-item-wrapper:hover .top-product-item-price-range.blog {
	opacity: 0;
}

.endorsement-section {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: 5%;
}

.endorsement-content-wrapper {
	width: 78%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.endorsement-text {
	width: 60%;
	font: Italic 18px/24px Josefin Sans;
	font-weight: 300;
	letter-spacing: 0px;
	color: #000000;
	text-align: center;
}

.endorsement-image-title-wrapper {
	width: 20%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.endorsement-image-title-wrapper img {
	width: 40%;
}

.endorsement-name {
	font: 20px/14px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
	margin-bottom: 3%;
	margin-top: 10%;
}

.endorsement-title {
	font: Italic 13px/24px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
}

.top-service-item-inner-worker-svg.installation {
	margin-left: -5%;
	width: 80%;
	/* right: 6px; */
	/* margin-bottom: 4rem; */
}

.top-service-item-inner-worker-svg.installation img {
	width: 100%;
}

.top-service-item-inner-hanging.installation {
	position: absolute;
	left: -16%;
	bottom: 0;
	margin-bottom: 15%;
	width: 20%;
	transition: all ease-in-out 0.3s;
}

.top-service-item-inner-hanging.installation img {
	width: 100%;
}

.top-service-item-inner-background.installation {
	border-bottom: none;
	align-items: flex-start;
}

.top-service-item-inner-background.installation img {
	width: 100%;
}

/* .top-service-item-inner-svgs.installation {
	margin-top: 10%;
} */

.top-service-item:hover
	.top-service-item-content
	.top-service-item-inner-svgs
	.top-service-item-inner-worker-svg.installation {
	margin-left: -5%;
}

.top-service-item:hover
	.top-service-item-content
	.top-service-item-inner-svgs.installation {
	/* margin-top: 5%; */
	transform: scale(1.08);
}

.top-service-item:hover
	.top-service-item-content
	.top-service-item-inner-svgs.installation
	.top-service-item-inner-hanging.installation {
	margin-bottom: 5%;
}

/* .top-service-item-inner-hanging.installation {
	transition: all ease-in-out 0.3s;
} */

/* .top-service-item:hover .top-service-item-background-image.installation {
	transform: scale(1.4);
} */

.top-service-item-background-image.installation {
	left: -5%;
}

.top-service-item-content-img {
}

.top-service-item-content-img img {
	width: 100%;
}

@media only screen and (max-width: 500px) {
	.top-product-item-name {
		font: normal normal bold 1.3rem/28px Josefin Sans;
	}
	.top-product-title-link-wrapper {
		width: 90%;
		justify-content: center;
		margin-bottom: 5%;
	}

	.top-product-link {
		width: 30%;
	}

	.top-service-item-background-image.installation {
		left: 0;
	}

	/* .top-product-title {
		margin-left: 0%;
	}

	.top-product-link {
		margin-right: 0%;
	} */

	.top-product-item-price-range {
		font: normal normal normal 20px/39px Tahoma;
		letter-spacing: 0px;
		color: #000000;
	}

	.top-product-items-list {
		flex-direction: column;
		height: auto;
		align-items: center;
		background: none;
	}

	.top-product-item-wrapper {
		width: 90%;
		background: white;
	}

	.top-product-item-wrapper .top-product-item-button {
		opacity: 1;
		margin-bottom: 5%;
	}

	.jumbotron-section-content img {
		width: 100%;
	}

	.jumbotron-section-content-text {
		text-align: left;
		font: normal normal bold 28px/34px Josefin Sans;
	}

	.jumbotron-section-content {
		width: 100%;
	}

	.special-offers-wrapper {
		position: absolute;
		top: 80%;
		width: 90%;
		align-self: center;
		padding: 0;
		height: 19rem;
		justify-content: center;
	}

	.special-offers-content h2 {
		margin-bottom: 4%;
		margin-top: 5%;
	}

	.special-offers-content a {
		width: 50%;
		height: 3rem;
		margin-top: 5%;
	}

	.special-offers-content p {
		font: 0.9rem Josefin Sans;
	}

	.header-sub-section {
		margin-top: 70%;
		width: 100%;
	}

	.header-sub-section-image {
		width: 50%;
		top: -20%;
	}

	.header-sub-section-content {
		margin-top: 20%;
		width: 100%;
		margin-left: 0;
		padding-top: 10%;
		padding-bottom: 10%;
		justify-content: center;
		padding-left: 7%;
		padding-right: 7%;
	}

	.header-sub-section-large-text {
		font: normal normal bold 60px/49px Josefin Sans;
		margin-top: 5%;
	}

	.special-offers-content div {
		margin-bottom: 3%;
	}

	.header-sub-section-small-text {
		text-align: left;
		font: normal normal normal 19px/26px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
		opacity: 1;
	}

	.top-product-title-link-wrapper.landing {
		justify-content: space-between;
		justify-content: space-between;
		margin-top: 15%;
		margin-bottom: 2%;
	}

	.top-product-title-link-wrapper.landing.services {
		width: 100%;
		padding-left: 2%;
		padding-right: 2%;
		box-sizing: border-box;
	}

	.top-product-section {
		width: 100%;
		align-items: center;
	}

	.top-services-section-wrapper {
		width: 100%;
	}

	.top-service-item-list {
		flex-direction: column;
		align-items: center;
		margin-bottom: 15%;
	}

	.top-service-item {
		width: 65%;
		margin-top: 15%;
	}

	/* .top-service-item .top-service-item-background-image {
		transform: scale(1.4);
	} */

	.top-service-item .top-service-item-content .top-service-item-text,
	.top-service-item:hover .top-service-item-content .top-service-item-link {
		opacity: 1;
	}

	.top-service-item-link {
		opacity: 1;
	}

	.renewable-energy-wrapper {
		width: 100%;
	}

	.renewable-energy-content {
		flex-direction: column;
	}
	.renewable-energy-section-image {
		width: 100%;
	}

	.renewable-packages-container {
		width: 100%;
	}

	.renewable-energy-section {
		margin-top: 10%;
	}

	.renewable-energy-title-wrapper {
		box-sizing: border-box;
		margin-left: 0;
		padding-left: 5%;
	}

	.renewable-packages-title {
		font: normal normal bold 30px/32px Josefin Sans;
		letter-spacing: 0px;
		color: #062a4e;
		margin-bottom: 5%;
	}

	.renewable-packages-text {
		font: normal normal normal 14px/18px Josefin Sans;
		letter-spacing: 0px;
		color: #000000;
	}

	.top-product-item-price-range.blog {
		opacity: 0;
		font: normal normal normal 14px/18px Josefin Sans;
	}

	.first-section-left {
	}

	.first-section-left h1 {
		font: normal normal bold 28px/34px Josefin Sans;
		letter-spacing: 0px;
		color: #fc2a00;
	}
}

/* Large screens ----------- */
@media only screen and (min-width: 1224px) {
	/* Styles */
	.special-offers-content h2 {
		font: 22px/25px Josefin Sans;
		/* font-weight: 600; */
	}

	.des-details {
		font: normal normal normal 14px/23px Josefin Sans;
	}

	.product-details-price {
		font: normal normal normal 20px/39px Tahoma;
	}

	.product-details-name {
		font: normal normal 600 20px/32px Josefin Sans;
	}

	.product-details-summary {
		font: normal normal normal 14px/23px Josefin Sans;
	}

	.header-sub-section-small-text {
		font: normal normal normal 20px/20px Josefin Sans;
	}

	.jumbotron-section-content-text {
		font: normal normal bold 53px/64px Josefin Sans;
		top: 10%;
	}

	.special-offers-content div {
		font: 46px Josefin Sans;
		font-weight: 600;
	}

	.special-offers-content a {
		width: 40%;
		height: 60px;
		font: Bold 14px/39px Josefin Sans;
		margin-top: 5%;
	}

	.top-product-title {
		font: 24px/50px Josefin Sans;
		font-weight: 600;
	}

	.renewable-packages-title {
		text-align: left;
		font: Bold 30px/50px Josefin Sans;
		letter-spacing: 0px;
	}

	.renewable-energy-title-wrapper {
		width: 100%;
		font: 24px/49px Josefin Sans;
		font-weight: 600;
		letter-spacing: 0px;
		color: #062a4e;
		margin-left: 3%;
		display: flex;
		justify-content: flex-start;
	}

	.special-offers-content p {
		font: 17px Josefin Sans;
		font-weight: 600;
	}

	.header-sub-section-image {
		top: -40%;
		width: 36%;
	}

	.top-product-item-price-range.blog ~ .top-product-item-button {
		font: Bold 14px/39px Josefin Sans;
	}

	.top-product-item-name {
		font: 18px Josefin Sans;
		font-weight: 600;
	}

	.products-filter-item {
		font: Bold 14px/25px Josefin Sans;
	}

	.top-product-item-price-range {
		font: 16px Josefin Sans;
	}

	.top-service-item-title {
		font: 18px/39px Josefin Sans;
		font-weight: 600;
	}

	.top-service-item-text {
		font: 13px Josefin Sans;
	}

	.renewable-packages-text {
		font: 16px/18px Josefin Sans;
	}

	.footer-link-list {
		font: 12px/20px Josefin Sans;
	}

	.services-content-left-header {
		font: 25px/42px Josefin Sans;
		font-weight: 600;
	}

	.services-content-left-text {
		font: 15px/18px Josefin Sans;
	}

	.top-product-link {
		font: 14px/39px Josefin Sans;
	}

	.special-offers-content {
		width: 80%;
	}

	.react-select__placeholder {
		font: Bold 12px Josefin Sans;
	}

	.services-content-left-button {
		height: 3.5rem;
		font: Bold 14px/25px Josefin Sans;
	}

	.top-product-items-list {
		min-height: 21rem;
	}
}

@media only screen and (min-width: 1824px) {
	/* Styles */
	.special-offers-content h2 {
		font: 26px/25px Josefin Sans;
		/* font-weight: 600; */
	}

	.des-details {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.product-details-price {
		font: normal normal normal 24px/39px Tahoma;
	}

	.product-details-name {
		font: normal normal 600 24px/32px Josefin Sans;
	}

	.product-details-summary {
		font: normal normal normal 18px/23px Josefin Sans;
	}

	.jumbotron-section-content-text {
		font: normal normal bold 66px/80px Josefin Sans;
		top: 15%;
	}

	.header-sub-section-small-text {
		font: normal normal normal 24px/30px Josefin Sans;
	}

	.special-offers-content div {
		font: 60px/80px Josefin Sans;
		font-weight: 600;
	}

	.special-offers-content a {
		width: 200px;
		height: 75px;
		font: Bold 18px/39px Josefin Sans;
	}

	.special-offers-content {
		width: 80%;
	}

	.products-filter-item {
		font: bold 18px/25px Josefin Sans;
	}

	.top-product-title {
		font: 30px/50px Josefin Sans;
		font-weight: 600;
	}

	.renewable-packages-title {
		text-align: left;
		font: Bold 38px/59px Josefin Sans;
		letter-spacing: 0px;
	}

	.renewable-energy-title-wrapper {
		width: 100%;
		font: 30px/49px Josefin Sans;
		font-weight: 600;
		letter-spacing: 0px;
		color: #062a4e;
		margin-left: 3%;
		display: flex;
		justify-content: flex-start;
	}

	.top-product-item-price-range.blog ~ .top-product-item-button {
		font: Bold 16px/39px Josefin Sans;
	}

	.special-offers-content p {
		font: 26px/25px Josefin Sans;
		font-weight: 600;
	}

	.top-product-item-name {
		font: 22px Josefin Sans;
		font-weight: 600;
	}

	.top-product-item-price-range {
		font: 20px Josefin Sans;
	}

	.top-service-item-title {
		font: 24px/39px Josefin Sans;
		font-weight: 600;
	}

	.top-product-link {
		font: 18px/49px Josefin Sans;
	}

	.top-service-item-text {
		font: 18px Josefin Sans;
	}

	.renewable-packages-text {
		font: 18px/18px Josefin Sans;
	}

	.footer-link-list {
		font: 12px/20px Josefin Sans;
	}

	.services-content-left-header {
		font: 30px/49px Josefin Sans;
		font-weight: 600;
	}

	.services-content-left-text {
		font: 18px/23px Josefin Sans;
	}

	.services-content-left-button {
		height: 4rem;
		font: Bold 18px/25px Josefin Sans;
	}
}
