.contact-us-head-office-left {
	width: 45%;
	position: relative;
}

.contact-us-location-title {
	font: Bold 70px Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	margin-top: 5%;
	margin-bottom: 0px;
	padding-left: 0;
}

.contact-us-location-left-body {
	display: flex;
	flex-direction: column;
	margin-top: 5%;
	position: relative;
	left: 5%;
}

.contact-us-location-state {
	text-align: left;
	font: 25px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	font-weight: 600;
	width: 65%;
	/* margin-bottom: 5%; */
}

.contact-us-location-state.sub-right {
	margin-left: 10%;
}

.contact-us-location-content-wrapper {
	display: flex;
	/* background: #f9c4ba1a 0% 0% no-repeat padding-box; */
}

.contact-us-location-content-image-wrapper {
	width: 17%;
	margin-right: 5%;
	margin-top: 5%;
}

.contact-us-location-content-image-wrapper svg {
	width: 100%;
}

.contact-us-location-content {
	margin-top: 2%;
	background: #f9c4ba1a 0% 0% no-repeat padding-box;
	padding: 5%;
	padding-right: 15%;
	padding-left: 10%;
	z-index: 1;
}

.contact-us-location-content-address {
	text-align: left;
	font: 14px/20px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
}

.contact-us-location-content-phone-wrapper {
	display: flex;
	margin-top: 7%;
}

.contact-us-location-phone-image {
	margin-right: 5%;
	width: 10%;
}

.contact-us-location-phone-image svg {
	width: 100%;
}

.contact-us-location-phone-numbers {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	text-decoration: none;
}

.location-phone-number {
	width: 50%;
	/* margin-bottom: 5%; */
	text-align: left;
	font: 14px/20px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	margin-top: 0;
	min-width: fit-content;
	text-decoration: none;
}

.location-phone-number a {
	text-decoration: none;
	color: #062a4e;
}

.contact-us-head-office-right {
	width: 55%;
	position: relative;
	display: flex;
	justify-content: center;
}

.contact-us-head-office-right-image {
	width: 80%;
	position: relative;
	top: 0;
	z-index: 1;
	margin-bottom: 10%;
}

.contact-us-head-office-right-image img {
	width: 100%;
}

.contact-us-head-office-right-background {
	position: absolute;
	height: 55%;
	background: #caeef4 0% 0% no-repeat padding-box;
	opacity: 0.2;
	width: 100%;
	bottom: 0;
	z-index: 0;
}

.services-content-item.contact {
	align-items: flex-start;
}

.services-content-item.contact-sub {
	margin-top: 0%;
	align-items: flex-start;
}

.contact-us-location-left-body.sub {
	width: 45%;
	left: 2%;
	margin-top: 1%;
	/* left: 0; */
}

.contact-us-location-left-body.sub-right {
	margin-top: 10%;
	width: 55%;
	box-sizing: border-box;
	position: relative;
	left: 0;
	display: flex;
	justify-content: flex-start;
}

.contact-us-location-content-image-wrapper.sub-right {
	margin-left: 5%;
	margin-right: 7%;
}

.contact-us-location-content.sub-right {
	background: none;
	margin-top: 0%;
	padding-left: 0;
}

.contact-us-location-content-wrapper.sub-right {
	display: flex;
	padding-left: 5%;
	box-sizing: border-box;
	margin-top: 1%;
	width: 90%;
	background: #caeef43d 0% 0% no-repeat padding-box;
}

.contact-section-left {
	width: 45%;
}

.contact-section-left-header {
	font: Bold 70px Josefin Sans;
	letter-spacing: 0px;
	color: #1fc6cd;
	/* margin-top: 5%; */
	margin-bottom: 0px;
	width: 100%;
}

.contact-section-left-form {
	display: flex;
	flex-direction: column;
	margin-top: 5%;
	position: relative;
	left: 4%;
	margin-top: 1%;
	/* padding-top: 10%; */
	align-items: flex-start;
}

.contact-section-left-coloured {
	width: 100%;
	padding-top: 10%;
	background: #caeef43d 0% 0% no-repeat padding-box;
	padding-left: 5%;
	box-sizing: border-box;
	padding-right: 10%;
	padding-bottom: 2%;
	/* border-radius: 1rem; */
}

.contact-section-left-double-input-item {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5%;
}

.contact-section-left-double-input-item > input,
.contact-section-left-double-input-item > div {
	width: 46%;
	height: 3rem;
	background: #ffffff 0% 0% no-repeat;
	border: none;
	padding-left: 5%;
	box-sizing: border-box;
	border-radius: 0.5rem;
}

.contact-section-left-double-input-item > input::placeholder {
	font: Bold 14px josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.contact-section-left-single-input-item {
	padding-left: 5%;
	width: 100%;
	border: none;
	height: 3rem;
	margin-top: 5%;
	margin-bottom: 5%;
	box-sizing: border-box;
	border-radius: 0.5rem;
}

.contact-section-left-single-input-item.select {
	width: 85%;
	height: 3rem;
	margin-left: 5%;
	padding-left: 0;
	display: flex;
}

.contact-section-left-single-input-item.select > div {
	border: none;
	padding-left: 3%;
	width: 100%;
}

.contact-section-left-single-input-item.message {
	padding: 5%;
	height: 8rem;
	width: 85%;
	margin-left: 5%;
	margin-top: 1%;
}

.contact-section-left-single-input-item::placeholder {
	font: Bold 14px Josefin Sans;
	letter-spacing: 0px;
	color: #bebebe !important;
}

.mailto-button {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mailto-button a {
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: 3rem; */
	background: #fc2a00 0% 0% no-repeat padding-box;
	text-decoration: none;
	color: white;
	text-align: center;
	font: Bold 14px/49px Josefin Sans;
	letter-spacing: 0px;
	color: #ffffff;
}

.contact-section-right {
	width: 55%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-section-right a {
	display: flex;
	margin-bottom: 5%;
	margin-top: 10%;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.contact-section-right-mailto-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-section-right-mailto-content-image {
	width: 20%;
	margin-bottom: 5%;
}

.contact-section-right-mailto-content-image img {
	width: 100%;
}

.contact-section-right-mailto-content-email {
	font: 28px Josefin Sans;
	letter-spacing: 0px;
	color: #062a4e;
	font-weight: 200;
}

.contact-section-right-image {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.contact-section-right-image-background {
	position: absolute;
	height: 55%;
	background: #f9c4ba 0% 0% no-repeat padding-box;
	opacity: 0.18;
	width: 100%;
	top: 0;
	z-index: 0;
}

.contact-section-right-image-wrapper {
	width: 80%;
	position: relative;
	top: 0;
	z-index: 1;
	margin-top: 11%;
	display: flex;
	justify-content: center;
}

.contact-section-right-image-wrapper img {
	width: 80%;
}

#location-select {
	padding-left: 0;
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

#location-select > div {
	width: 100%;
	/* height: 100%; */
	padding-left: 5%;
	border: none;
	display: flex;
}

.bottom-label {
	margin-left: 9%;
	margin-top: 3%;
	text-align: left;
	font: 14px/20px Josefin Sans;
	letter-spacing: 0px;
	color: #707070;
}

@media only screen and (max-width: 500px) {
	.services-content-item.contact {
		flex-direction: column;
		align-items: center;
		margin-bottom: 5%;
	}

	.contact-us-head-office-left {
		width: 100%;
	}

	.contact-us-head-office-right {
		width: 90%;
	}

	.contact-us-location-title {
		padding-left: 5%;
		font: Bold 40px Josefin Sans;
	}

	.contact-us-location-state {
		font: 19px Josefin Sans;
		font-weight: 600;
		margin-bottom: 5%;
	}

	.services-content-item.contact-sub {
		flex-direction: column;
		align-items: center;
		margin-bottom: 5%;
	}

	.contact-us-head-office-right-background {
		height: 100%;
	}

	.contact-us-location-left-body {
		width: 95%;
		box-sizing: border-box;
	}

	.contact-us-location-left-body.sub-right {
		width: 90%;
	}

	.contact-us-location-left-body.sub {
		width: 90%;
	}

	.contact-us-location-state.sub-right {
		margin-left: 0%;
	}

	.contact-section-left {
		width: 100%;
	}

	.contact-us-location-content-wrapper {
		width: 100%;
	}

	.contact-section-left-form {
		left: 0;
		align-items: center;
	}

	.contact-section-left-header {
		font: Bold 40px Josefin Sans;
		margin-bottom: 5%;
		padding-left: 5%;
		box-sizing: border-box;
	}

	.contact-section-left-coloured {
		padding-left: 5%;
		padding-right: 5%;
	}

	.bottom-label {
		align-self: start;
	}

	.contact-section-left-single-input-item.select {
		width: 90%;
		margin-left: 0%;
	}

	.contact-section-left-single-input-item.message {
		margin-left: 0;
		width: 90%;
	}

	.contact-section-right {
		width: 90%;
	}

	.contact-section-left-single-input-item::placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}

	.contact-section-left-double-input-item > input::placeholder {
		font: normal normal 14px Josefin Sans;
		letter-spacing: 0px;
		color: #7e7e7e !important;
	}
}

@media only screen and (min-width: 1224px) {
	.contact-us-location-content-address {
		font: 16px/20px Josefin Sans;
	}
}

@media only screen and (min-width: 1824px) {
	/* Styles */

	.contact-us-location-title {
		font: normal normal bold 90px/49px Josefin Sans;
	}

	.contact-us-location-state {
		font: normal normal 600 30px/49px Josefin Sans;
	}

	.contact-us-location-content-address {
		font: normal normal normal 18px/30px Josefin Sans;
	}

	.location-phone-number {
		font: normal normal normal 16px/34px Josefin Sans;
	}

	.contact-section-right-mailto-content-email {
		font: normal normal 300 38px/116px Josefin Sans;
	}
}
