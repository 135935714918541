.services-section-container {
	width: 100%;
	display: flex;
	justify-content: center;
	background: #f7f7f7 0% 0% no-repeat padding-box;
}

.services-section-content-wrapper {
	width: 78%;
	display: flex;
	flex-direction: column;
	margin-bottom: 5%;
}

.services-content-item {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	margin-top: 5%;
}

.services-content-left-group {
	width: 35%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.services-content-left-header {
	font: 22px/42px Josefin Sans;
	font-weight: 600;
	letter-spacing: 0px;
	color: #062a4e;
	margin-bottom: 10%;
}

.services-content-left-text {
	font: 13px/18px Josefin Sans;
	letter-spacing: 0px;
	color: #000000;
	margin-bottom: 10%;
}

.services-content-right-group {
	width: 55%;
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	align-self: flex-end;
}

.services-content-right-worker-svg {
	display: flex;
	width: 70%;
	position: absolute;
	left: 10%;
}

.services-content-right-background-svg {
	width: 70%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.services-content-right-background-svg svg {
	width: 100%;
	height: 100%;
}

.services-content-right-worker-svg svg {
	width: 100%;
	height: 100%;
}

.services-content-right-worker-svg img {
	width: 75%;
}

.services-content-item.reverse {
	flex-direction: row-reverse;
}

.services-content-left-group.reverse {
	align-items: flex-end;
}

.services-content-left-text.reverse {
	text-align: right;
}

.services-content-right-group.reverse .services-content-right-worker-svg {
	right: 10%;
	left: auto;
	display: flex;
	justify-content: flex-end;
}

.services-content-right-group.reverse {
	justify-content: flex-start;
}

.services-content-right-background-img img {
	width: 100%;
	height: 100%;
}

.services-content-right-background-img {
	display: flex;
	position: absolute;
	left: 10%;
}

.services-content-left-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60%;
	height: 3rem;
	font: Bold 12px/25px Josefin Sans;
	letter-spacing: 0px;
	color: #fc2a00;
	border: 1px solid #fc2a00;
	background-size: 200% 200%;
	transition: all 0.3s;
	background-image: linear-gradient(to right, #fc2a00 50%, white 0);
	background-position: right;
	text-decoration: none;
	cursor: pointer;
}

.services-content-left-button:hover {
	background-position: left;
}

.services-content-left-button:hover {
	color: #ffffff;
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 500px) {
	.services-section-content-wrapper {
		width: 100%;
	}

	.services-content-item {
		flex-direction: column-reverse;
	}

	.services-content-right-group {
		align-self: flex-end;
		width: 70%;
	}

	.services-content-left-group {
		width: 85%;
	}

	.services-content-item.reverse {
		flex-direction: column-reverse;
	}

	.services-content-right-group.reverse {
		align-self: flex-start;
	}

	.services-content-left-header {
		margin-bottom: 5%;
		font: 22px/30px Josefin Sans;
	}

	.reverse.last {
		margin-bottom: 10%;
	}
}
